import React, { FunctionComponent, useEffect, useState } from "react";
import { useUser } from "modules/context/UserProvider";
import { useNavigate } from "react-router";
import { useCollectionStatusQuery, useProfileQuery } from "modules/utils/hooks";
import { useApp } from "modules/context/AppProvider";
import apiCall, { Endpoints } from "modules/api";
import { RoutesPaths } from "modules/routes";
import styles from "./WellDone.module.scss";
import Bugsnag from "@bugsnag/js";
import Footer from "components/Footer/Footer";
import Button, { ButtonTypes } from "components/Button/Button";
import { images } from "modules/images";
import RevokeConsentModalContent, {
  revokeConsentModalTitle,
} from "components/Modals/RevokeConsent/RevokeConsent";
import { useTranslation } from "react-i18next";

const WellDone: FunctionComponent = () => {
  const { data, refetch } = useCollectionStatusQuery();
  const { data: profile } = useProfileQuery();
  const { user } = useUser();
  const { showAlert } = useApp();
  const navigate = useNavigate();
  const { t } = useTranslation("common");

  const [tracked, setTracked] = useState(false);
  const [pending, setPending] = useState(false);

  const hasEnoughData =
    data && Object.values(data).some((status) => status.has_enough_data);

  useEffect(() => {
    const trackScreen = async () => {
      if (tracked) return;
      setPending(true);

      try {
        if (typeof user.token !== "string") throw new Error("token");

        const headers = {
          "Content-Type": "application/json",
          Authorization: user.token,
        };

        await apiCall.post(Endpoints.trackWellDone, {}, { headers });

        setTracked(true);
        setPending(false);
      } catch (error) {
        Bugsnag.notify(error);
      }
    };

    if (!profile) {
      return;
    }

    if (
      user &&
      !tracked &&
      !pending &&
      !profile?.waiting_data_screen_visited_at
    )
      trackScreen();

    const interval = setInterval(async () => {
      await refetch();

      if (hasEnoughData) navigate(RoutesPaths.thankYou);
    }, 15 * 1000);

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [
    user,
    tracked,
    refetch,
    pending,
    profile,
    navigate,
    hasEnoughData,
    profile?.waiting_data_screen_visited_at,
  ]);

  const handleOk = () => navigate(RoutesPaths.home);

  const onDisconnectHandle = () =>
    showAlert(
      t(revokeConsentModalTitle),
      <RevokeConsentModalContent isWellDoneScreen={true} />,
    );

  return (
    <div className={styles.wrapper}>
      <div className={styles.centerContainer}>
        <h1 className={styles.heading}>{t("well_done")}</h1>
        <div className={styles.infoContainer}>
          {t("well_done_info_1")}
          <br />
          <br />
          {t("well_done_info_2")}

          <br />
          <br />
          {t("well_done_info_3")}
        </div>
        <div className={styles.buttonContainer}>
          <Button
            type={ButtonTypes.big}
            text={t("button_got_it")}
            icon={images.rightArrow}
            clickHandler={handleOk}
          />
        </div>
        <div className={styles.bottomContainer}>
          <div className={styles.disconnectContainer}>
            {t("well_done_disconnect")}
            <span
              className={styles.disconnectButton}
              onClick={onDisconnectHandle}
            >
              {t("button_disconnect")}
            </span>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default WellDone;

import { FunctionComponent } from "react";
import styles from "./TextButton.module.scss";

const TextButton: FunctionComponent<{
  text: string;
  disabled?: boolean;
  clickHandler?: () => void;
}> = ({ text, disabled, clickHandler }) => (
  <button className={styles.button} disabled={disabled} onClick={clickHandler}>
    {text}
  </button>
);

export default TextButton;

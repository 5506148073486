import Button, { ButtonTypes } from "components/Button/Button";
import SecondaryButton from "components/SecondaryButton/SecondaryButton";
import { useApp } from "modules/context/AppProvider";
import { images } from "modules/images";
import { RoutesPaths } from "modules/routes";
import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Leave.module.scss";
import { useTranslation } from "react-i18next";

export const leaveModalTitle = "leave_the_process";

const LeaveModalContent: FunctionComponent = () => {
  const { closeAlert } = useApp();
  const navigate = useNavigate();
  const { t } = useTranslation("common");

  const handleLeave = () => {
    navigate(RoutesPaths.home);
    closeAlert();
  };

  return (
    <div className={styles.container}>
      <div className={styles.info}>{t("leave_modal_text")}</div>
      <div className={styles.buttonsContainer}>
        <div className={styles.firstButtonContainer}>
          <Button
            type={ButtonTypes.big}
            text={t("button_leave")}
            icon={images.rightArrow}
            clickHandler={handleLeave}
          />
        </div>
        <SecondaryButton text={t("button_cancel")} clickHandler={closeAlert} />
      </div>
    </div>
  );
};

export default LeaveModalContent;

import { FunctionComponent } from "react";
import { ButtonProps, ButtonTypes } from "components/Button/Button";
import SmallButton from "components/SmallButton/SmallButton";
import BigButton from "components/BigButton/BigButton";
import BackButton from "components/BackButton/BackButton";
import SecondaryButton from "../SecondaryButton/SecondaryButton";

const ButtonContent: FunctionComponent<ButtonProps> = ({
  type,
  text,
  icon,
  disabled,
}) => {
  const renderContent = () => {
    switch (type) {
      case ButtonTypes.small:
        return <SmallButton text={text} icon={icon} disabled={disabled} />;

      case ButtonTypes.big:
        return <BigButton text={text} icon={icon} disabled={disabled} />;

      case ButtonTypes.secondary:
        return <SecondaryButton text={text} disabled={disabled} />;

      case ButtonTypes.back:
        return <BackButton />;
    }
  };

  return <>{renderContent()}</>;
};

export default ButtonContent;

import { images } from "modules/images";
import { DeviceId } from "./constants";
import { RoutesPaths } from "./routes";
import { DeviceItemType } from "../screens/ChooseDevices/DeviceItem";

export const apps = [
  {
    name: DeviceId.apple,
    title: "Apple Health",
    subtitle: "",
    connectionRoute: RoutesPaths.connectAppleHealth,
    icon: images.appleHealthLogo,
  },
  {
    name: DeviceId.google,
    title: "Google Fit",
    subtitle: "",
    connectionRoute: RoutesPaths.connectGoogleFit,
    icon: images.googleFitLogo,
  },
];

export const devices: DeviceItemType[] = [
  {
    name: DeviceId.garmin,
    title: "Garmin",
    subtitle: "",
    connectionRoute: RoutesPaths.connectGarmin,
    icon: images.garminLogo,
  },
  {
    name: DeviceId.fitbit,
    title: "Fitbit",
    subtitle: "",
    connectionRoute: RoutesPaths.connectFitbit,
    icon: images.fitbitLogo,
  },
  {
    name: DeviceId.underarmour,
    title: "Map My Run",
    subtitle: "(Under Armour)",
    connectionRoute: RoutesPaths.connectUnderarmour,
    icon: images.underarmourLogo,
  },
  {
    name: DeviceId.googleTimeline,
    title: "Location History",
    connectionRoute: RoutesPaths.connectGoogleTimeline,
    icon: images.googleTimelineLogo,
  },
];

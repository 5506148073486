import { images } from "modules/images";
import React, { Context, ReactElement, useLayoutEffect, useState } from "react";
import { isMobileOnly, osName } from "react-device-detect";
import styles from "./DeviceProvider.module.scss";

type DeviceContextType = {
  isMobileOnly: boolean;
};

enum Orientations {
  "portrait",
  "landscape",
}

export const DeviceContext: Context<DeviceContextType> =
  React.createContext<DeviceContextType>(null);

const DeviceProvider: React.FC<{ children: ReactElement }> = ({ children }) => {
  function useDeviceOrientation() {
    const [orientation, setOrientation] = useState<Orientations | null>(null);
    useLayoutEffect(() => {
      function updateOrientation() {
        try {
          if (osName === "iOS") {
            const orientation =
              window.innerWidth > window.innerHeight
                ? Orientations.landscape
                : Orientations.portrait;
            setOrientation(orientation);
          } else {
            const orientation =
              window.screen.orientation.angle === 0
                ? Orientations.portrait
                : Orientations.landscape;
            setOrientation(orientation);
          }
        } catch (e) {
          console.log(e);
        }
      }
      window.addEventListener("resize", updateOrientation);
      updateOrientation();
      return () => window.removeEventListener("resize", updateOrientation);
    }, []);
    return orientation;
  }

  const orientation = useDeviceOrientation();

  return (
    <DeviceContext.Provider value={{ isMobileOnly }}>
      {isMobileOnly && orientation === Orientations.landscape && (
        <div
          className={isMobileOnly ? styles.mobileView : styles.none}
          style={{ height: "100%" }} // this is the fix for mobile firefox browser stretching the container
          // and yes, it has to be here, in inline styles, not in css file
        >
          <img
            src={images.rotate}
            alt="Rotate phome"
            className={styles.rotateImg}
          />
          <p>Please rotate your phone to portrait view</p>
        </div>
      )}

      {(!(isMobileOnly && orientation !== Orientations.portrait) ||
        !isMobileOnly) && <div>{children}</div>}
    </DeviceContext.Provider>
  );
};

export const useDevice = () => {
  return React.useContext(DeviceContext);
};

export default DeviceProvider;

import Button, { ButtonTypes } from "components/Button/Button";
import AppleTutorialMobileHint from "components/Modals/AppleTutorialMobileHint/AppleTutorialMobileHint";
import ToggleButton from "components/ToggleButton/ToggleButton";
import { images } from "modules/images";
import { FunctionComponent, ReactElement, useState } from "react";
import { osName, osVersion } from "react-device-detect";
import styles from "./AppleHealthTutorialMobile.module.scss";
import { ReactComponent as CloseButton } from "assets/images/icons/close.svg";
import { useTranslation } from "react-i18next";

interface TutorialsInterface {
  old: Array<ReactElement>;
  new: Array<ReactElement>;
}

interface AppleHealthTutorialMobileInterface {
  onClose: () => void;
}

const AppleHealthTutorialMobile: FunctionComponent<
  AppleHealthTutorialMobileInterface
> = ({ onClose }) => {
  const isIOS = osName === "iOS";
  const newIOSVersion = 12;
  const [hintOpened, setHintOpened] = useState(true);
  const { t } = useTranslation("common");

  const tutorials: TutorialsInterface = {
    old: [
      <>
        {t("ah_tutorial_open")}{" "}
        <span className={styles.bold}>
          {t("ah_tutorial_the")}
          <br />
          {t("ah_tutorial_ah_app")}
        </span>
        <br />
        {t("ah_tutorial_on_your_mob_pone")}
      </>,
      <>
        {t("ah_tutorial_select")}{" "}
        <span className={styles.bold}>{t("ah_tutorial_sources")}</span>{" "}
        {t("ah_tutorial_in_the")}
        <br />
        {t("ah_tutorial_menu_bar")}
      </>,

      <>
        {t("ah_tutorial_select_the")}
        <br />
        <span className={styles.bold}>"OneClick-Connect"</span> app
      </>,
      <>
        {t("ah_tutorial_select")}{" "}
        <span className={styles.bold}>
          {t("ah_tutorial_turn_all")} <br />
          {t("ah_tutorial_categories_on")}
        </span>{" "}
        {t("ah_tutorial_to_give")}
        <br />
        {t("ah_tutorial_your_consent")}
        <div className={styles.textDividerWrapper}>
          <div className={styles.textDivider}></div>
        </div>
        {t("ah_tutorial_if_you_want_to")}
        <br />
        <span className={`${styles.bold} ${styles.red}`}>
          {t("ah_tutorial_revoke_consent")}
        </span>{" "}
        {t("ah_tutorial_select")}
        <br />
        <span className={styles.bold}>
          {t("ah_tutorial_turn_all_categories_off")}
        </span>
      </>,
      <>
        {t("ah_tutorial_return_to")}
        <br />
        <span className={styles.bold}>“OneClick-Connect”</span>
        <br />
        {t("ah_tutorial_app_to_continue")}
      </>,
    ],
    new: [
      <>
        {t("ah_tutorial_open")}{" "}
        <span className={styles.bold}>
          {t("ah_tutorial_the")}
          <br />
          {t("ah_tutorial_ah_app")}
        </span>
        <br />
        {t("ah_tutorial_on_your_mobile_phone")}
      </>,

      <>
        {t("ah_tutorial_select")}{" "}
        <span className={styles.bold}>{t("ah_tutorial_profile")}</span>{" "}
        {t("ah_tutorial_on")}
        <br />
        {t("ah_tutorial_the_summary_page")}
      </>,
      <>
        {t("ah_tutorial_choose")}{" "}
        <span className={styles.bold}>{t("ah_tutorial_apps")}</span>{" "}
        {t("ah_tutorial_from")}
        <br />
        {t("ah_tutorial_the_list")}
      </>,
      <>
        {t("ah_tutorial_select_the")}
        <br />
        <span className={styles.bold}>“OneClick-Connect”</span>{" "}
        {t("ah_tutorial_app")}
      </>,
      <>
        {t("ah_tutorial_select")}{" "}
        <span className={styles.bold}>
          {t("ah_tutorial_turn_all")}
          <br />
          {t("ah_tutorial_categories_on")}
        </span>{" "}
        {t("ah_tutorial_on")}
        to give <br />
        {t("ah_tutorial_your_consent")}
        <div className={styles.textDividerWrapper}>
          <div className={styles.textDivider}></div>
        </div>
        {t("ah_tutorial_if_you_want_to")}
        <br />
        <span className={`${styles.bold} ${styles.red}`}>
          {t("ah_tutorial_to_disconnect")}
        </span>{" "}
        {t("ah_tutorial_select")}
        <br />
        <span className={styles.bold}>
          {t("ah_tutorial_turn_all_categories_off")}
        </span>
      </>,
      <>
        {t("ah_tutorial_return_to")}
        <br />
        <span className={styles.bold}>“OneClick-Connect”</span>
        <br />
        {t("ah_tutorial_app_to_continue")}
      </>,
    ],
  };

  const isNewerIOSVersionTutorial = () => {
    const splittedVersion = osVersion.split(".");
    const majorVersion = Number(splittedVersion[0]);

    return majorVersion >= newIOSVersion ? tutorials.new : tutorials.old;
  };

  const setDefaultTutorial = () => {
    if (osName !== "iOS") return tutorials.new;
    return isNewerIOSVersionTutorial();
  };

  const [tutorial, setTutorial] = useState(setDefaultTutorial());
  const [toggleMoved, setToggleMoved] = useState(false);
  const [step, setStep] = useState<number>(0);
  const isNewTutorial = tutorial === tutorials.new;

  const imagesRoute = isNewTutorial
    ? images.appleTutorial.new
    : images.appleTutorial.old;

  const addStep = () => {
    const newStep = step + 1;
    setStep(newStep);
  };

  const reduceStep = () => {
    const newStep = step - 1;
    setStep(newStep);
  };

  const resetStep = () => {
    setStep(0);
  };

  const changeTutorial = () => {
    setTutorial(tutorial === tutorials.new ? tutorials.old : tutorials.new);

    setToggleMoved(!toggleMoved);
    resetStep();
  };

  return (
    <div className={styles.mobileContainer}>
      <AppleTutorialMobileHint
        opened={hintOpened}
        onClose={() => {
          setHintOpened(false);
        }}
      />

      <div className={styles.mobileHeader}>
        <div className={styles.mobileHeaderSmallContainer}></div>
        <div
          className={`${styles.mobileHeaderTitle} ${
            !isIOS ? styles.smallMobileHeaderTitle : styles.withMargin
          }`}
        >
          Consent Tutorial
        </div>
        <div className={styles.mobileHeaderSmallContainer}>
          <button className={styles.closeButton} onClick={onClose}>
            <CloseButton />
          </button>
        </div>
      </div>

      <div className={styles.tutorialContainer}>
        {!isIOS && (
          <div>
            <div className={styles.mobileToggleContainer}>
              <div className={styles.mobileToggleInfo}>
                Toggle this switch if{" "}
                <span className={styles.boldInfo}>
                  screenshots do not match
                </span>{" "}
                your device
              </div>
              <ToggleButton
                toggled={toggleMoved}
                clickHandler={changeTutorial}
              />
            </div>
          </div>
        )}

        <div className={styles.tutorialImageContainer}>
          <div
            className={`${styles.tutorialImage} ${
              !isIOS ? styles.notIOSImage : ""
            } ${
              isNewTutorial ? styles.newTutorialImage : styles.oldTutorialImage
            }`}
          >
            <img src={imagesRoute[step]} alt="Tutorial step" />
          </div>

          {isNewTutorial && <div className={styles.separator}></div>}
        </div>

        <div className={styles.tutorialStep}>
          Step {(step + 1).toString()}/{tutorial.length.toString()}:
        </div>

        <div className={styles.tutorialText}>{tutorial[step]}</div>

        <div
          className={`${styles.buttonsContainer} ${
            !isIOS ? styles.notIOS : ""
          }`}
        >
          {step > 0 && (
            <div className={styles.backButtonContainer}>
              <Button type={ButtonTypes.back} clickHandler={reduceStep} />
            </div>
          )}
          <div className={styles.mainButtonContainer}>
            <Button
              type={ButtonTypes.big}
              icon={step === 0 ? images.rightArrow : null}
              text={step + 1 === tutorial.length ? "Done" : "Next"}
              clickHandler={step + 1 === tutorial.length ? onClose : addStep}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppleHealthTutorialMobile;

import { handlePreventDefault } from "modules/utils";
import { FunctionComponent } from "react";
import useMedia from "use-media";
import styles from "./AboutDataCollection.module.scss";
import { ReactComponent as CloseButton } from "assets/images/icons/close.svg";
import { ReactComponent as ContactButtonWhite } from "assets/images/icons/contact-us-white.svg";
import { useTranslation } from "react-i18next";

interface Props {
  onClose?: () => void;
}

const AboutDataCollection: FunctionComponent<Props> = ({ onClose }) => {
  const isWide = useMedia({ minWidth: "1000px" });
  const { t } = useTranslation("common");
  const handleContactUs = () => {
    onClose();
    document.getElementById("contact-us-button").click();
  };

  const handleBackgroundClick = () => {
    if (isWide) onClose();
  };

  return (
    <div
      className={styles.wrapper}
      onClick={handleBackgroundClick}
      style={{ height: "100%" }}
    >
      <div className={styles.container} onClick={handlePreventDefault}>
        <div className={styles.top}>
          <div className={styles.header}>
            <h1>{t("about_data_collection_title")}</h1>
            <CloseButton onClick={onClose} className={styles.closeButton} />
          </div>
          <div className={styles.content}>
            {t("about_data_collection_text_1")}
            <br />
            <br />
            {t("about_data_collection_text_2")}
            <br />
            <br />
            {t("about_data_collection_text_3")}
            <br />
            <br />
            {t("about_data_collection_text_4")}
            <br />
            <br />
            {t("about_data_collection_text_5")}
          </div>
        </div>
        <div className={styles.bottom}>
          <button className={styles.contactButton} onClick={handleContactUs}>
            <p>{t("button_contact_us")}</p>
            <ContactButtonWhite style={{ marginLeft: "5px" }} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default AboutDataCollection;

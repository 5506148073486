import styles from "./Footer.module.scss";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation("common");

  return (
    <div className={styles.footerContainer}>
      {t("footer_powered_by")}&nbsp;
      <strong className={styles.strong}>Qumata</strong>
    </div>
  );
};

export default Footer;

import ContactUsButtonBig from "components/ContactUsButtonBig/ContactUsButtonBig";
import { FunctionComponent } from "react";
import styles from "./UserNotFound.module.scss";
import { useTranslation } from "react-i18next";

export const userNotFountModalTitle = "user_not_found_modal_title";

const UserNotFoundModalContent: FunctionComponent = () => {
  const { t } = useTranslation("common");

  return (
    <div className={styles.container}>
      <div className={styles.info}>
        {t("user_not_found_modal_text_1")}
        <br />
        <br />
        {t("user_not_found_modal_text_2")}
      </div>
      <div className={styles.buttonsContainer}>
        <ContactUsButtonBig />
      </div>
    </div>
  );
};

export default UserNotFoundModalContent;

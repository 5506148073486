import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, FormikValues } from "formik";
import classNames from "classnames";
import Select from "react-select";
import Bugsnag from "@bugsnag/js";
import moment from "moment";
import { Measure, useCollectionStatusQuery } from "modules/utils/hooks";
import HeightPicker from "./HeightPicker";
import WeightPicker from "./WeightPicker";
import Pending from "components/Pending";
// import Button from "components/Button";
import FormField from "./Field";
import { useProfileMutation, useProfileQuery } from "modules/utils/hooks";
import { profileSchema, months, years, range } from "modules/helper";
import Layout from "components/Layout/Layout";
import { RoutesPaths } from "modules/routes";
import { useApp } from "modules/context/AppProvider";
import "./styles.scss";
import { useUser } from "modules/context/UserProvider";
import changeRedirectURL from "modules/utils/redirect";
import apiCall, { Endpoints } from "modules/api";
import DefaultErrorModalContent, {
  defaultErrorModalTitle,
} from "components/Modals/DefaultError/DefaultError";
import Button, { ButtonTypes } from "components/Button/Button";
import { images } from "modules/images";
import RadioPicker from "components/RadioPicker/RadioPicker";
import Footer from "components/Footer/Footer";
import { useTranslation } from "react-i18next";

const yearOptions = years.map((year) => ({
  value: year,
  label: year,
}));

export type InputField =
  | "day"
  | "month"
  | "year"
  | "gender"
  | "height"
  | "smoking"
  | "weight"
  | "height_unit"
  | "weight_unit";

const Profile: React.FunctionComponent<{}> = () => {
  const { user } = useUser();
  const { data: profileData, isLoading: isProfileLoading } = useProfileQuery();
  const { mutate, isLoading: isProfileMutateLoading } = useProfileMutation();
  const { data: providersStatus } = useCollectionStatusQuery();
  const navigate = useNavigate();
  const { showAlert } = useApp();
  const { t } = useTranslation("common");

  const monthOptions = months.map((month, index) => ({
    value: `${index + 1}`,
    label: t(month),
  }));

  const genderOptions = [
    { label: t("gender_male"), value: "male" },
    { label: t("gender_female"), value: "female" },
  ];

  const smokingOptions = [
    { label: t("smoking_never"), value: "never" },
    {
      label: t("smoking_former"),
      value: "former",
    },
    { label: t("smoking_yes"), value: "yes" },
  ];

  const hasEnoughData =
    providersStatus &&
    Object.values(providersStatus).some((status) => status.has_enough_data);

  const [isSubmitted, setSubmitted] = useState(false);
  const [initialValues, setInitialValues] = useState<FormikValues>({});

  const submit = async (submitData: FormikValues): Promise<void> => {
    const {
      day,
      month,
      year,
      gender,
      height,
      smoking,
      weight,
      height_unit,
      weight_unit,
    } = submitData;

    const dob = moment(`${year}-${month}-${day}`, "YYYY-M-D").format(
      "DD/MM/YYYY",
    );

    const profile = {
      dob,
      gender,
      height,
      weight,
      smoking,
      height_unit,
      weight_unit,
    };

    try {
      mutate(profile);

      if (user.redirect) {
        const changedRedirectURL = changeRedirectURL(
          user.redirect,
          hasEnoughData,
        );

        try {
          if (typeof user.token !== "string") throw new Error("token");

          const headers = {
            "Content-Type": "application/json",
            Authorization: user.token,
          };

          await apiCall.post(
            hasEnoughData ? Endpoints.trackThankYou : Endpoints.trackWellDone,
            {},
            { headers },
          );
        } catch (error) {
          console.log(error);
          Bugsnag.notify(error);
        }

        window.location.replace(changedRedirectURL);
      } else {
        navigate(RoutesPaths.conditions);
      }
    } catch (error) {
      Bugsnag.notify(error);
      showAlert(defaultErrorModalTitle, <DefaultErrorModalContent />);
    }
  };

  const formStyle = classNames("profile-data-form", {
    "form-submitted": isSubmitted,
  });

  useEffect(() => {
    if (!isProfileLoading && profileData) {
      const { dob, gender, height, weight, smoking, height_unit, weight_unit } =
        profileData;

      const birthday = dob ? dob.split("/") : [];

      setInitialValues({
        gender,
        height,
        weight,
        smoking,
        day: birthday[0],
        month: birthday[1],
        year: birthday[2],
        weight_unit: weight_unit || Measure.kg,
        height_unit: height_unit || Measure.Centimeters,
      });
    }
  }, [isProfileLoading, profileData]);

  return isProfileLoading || isProfileMutateLoading ? (
    <Pending />
  ) : (
    <Layout progressBarPercent={60}>
      <div className="profile">
        <div className="profile-container">
          <div className="profile-header">{t("profile_data")}</div>
          <Formik
            validationSchema={profileSchema}
            onSubmit={submit}
            initialValues={initialValues}
            enableReinitialize={true}
          >
            {({
              values,
              errors,
              handleSubmit,
              setFieldValue,
              setFieldTouched,
            }) => {
              const onFieldChange = (
                field: InputField,
                value: string | number,
              ) => {
                if (isSubmitted) {
                  setSubmitted(false);
                }

                setFieldTouched(field);
                setFieldValue(field, value);
              };

              // !TODO find better solution:
              const daysByMonth =
                moment(
                  `${values.year}-${values.month}`,
                  "YYYY-MM",
                ).daysInMonth() + 1 || 32;
              const days = Array.from(range(1, daysByMonth, 1));
              const dayOptions = days.map((day) => ({
                value: day,
                label: day,
              }));

              const dayValue = dayOptions.find(
                ({ value }) => parseFloat(value) === parseInt(values.day),
              );

              const monthValue = monthOptions.find(
                ({ value }) => value === parseInt(values.month).toString(),
              );

              const yearValue = yearOptions.find(
                ({ value }) => value.toString() === values.year,
              );

              const getDaysByMonth = (month, year) => {
                const daysByMonth = moment(
                  `${year || values.year || 2003}-${
                    month || values.month || "1"
                  }`,
                  "YYYY-MM",
                ).daysInMonth();

                return daysByMonth;
              };

              const checkCorrectDay = (
                field: string,
                value: string | number,
              ) => {
                if (!dayValue) return;

                if (field === "month") {
                  if (dayValue.value > getDaysByMonth(value, null)) {
                    onFieldChange("day", getDaysByMonth(value, null));
                  }
                }

                if (field === "year") {
                  if (dayValue.value > getDaysByMonth(null, value)) {
                    onFieldChange("day", getDaysByMonth(null, value));
                  }
                }
              };

              return (
                <>
                  <div className={formStyle}>
                    <FormField
                      title={t("date_of_birth")}
                      error={
                        isSubmitted &&
                        ((typeof errors.day === "string" && t(errors.day)) ||
                          (typeof errors.month === "string" &&
                            t(errors.month)) ||
                          (typeof errors.year === "string" && t(errors.year)))
                      }
                      className="birthdate-selector sm-margin-vertical"
                    >
                      <div className="select-container">
                        <Select
                          options={dayOptions}
                          isSearchable={false}
                          defaultValue={dayValue}
                          value={dayValue}
                          className="day-select dropdown-container"
                          classNamePrefix="dropdown"
                          placeholder={t("placeholder_day")}
                          onChange={(e) => onFieldChange("day", e.value)}
                        />
                        <Select
                          options={monthOptions}
                          isSearchable={false}
                          defaultValue={monthValue}
                          value={monthValue}
                          className="month-select dropdown-container"
                          classNamePrefix="dropdown"
                          placeholder={t("placeholder_month")}
                          onChange={(e) => {
                            onFieldChange("month", e.value);
                            checkCorrectDay("month", e.value);
                          }}
                        />
                        <Select
                          options={yearOptions}
                          isSearchable={false}
                          defaultValue={yearValue}
                          value={yearValue}
                          className="year-select dropdown-container"
                          classNamePrefix="dropdown"
                          placeholder={t("placeholder_year")}
                          onChange={(e) => {
                            onFieldChange("year", e.value);
                            checkCorrectDay("year", e.value);
                          }}
                        />
                      </div>
                    </FormField>

                    <FormField
                      title={t("gender_at_birth")}
                      error={
                        isSubmitted &&
                        typeof errors.gender === "string" &&
                        t(errors.gender)
                      }
                      className="gender-at-birth"
                    >
                      <RadioPicker
                        title=""
                        name="gender"
                        options={genderOptions}
                        currentValue={values.gender}
                        setFieldValue={setFieldValue}
                        error={errors.gender}
                      />
                    </FormField>

                    <FormField
                      title={t("profile_smoking")}
                      error={
                        isSubmitted &&
                        typeof errors.smoking === "string" &&
                        t(errors.smoking)
                      }
                      className="flex-1"
                    >
                      <RadioPicker
                        title=""
                        name="smoking"
                        options={smokingOptions}
                        currentValue={values.smoking}
                        setFieldValue={setFieldValue}
                        error={errors.smoking}
                      />
                    </FormField>

                    <HeightPicker
                      height={values.height}
                      measure={values.height_unit}
                      error={
                        isSubmitted &&
                        typeof errors.height === "string" &&
                        t(errors.height)
                      }
                      onFieldChange={onFieldChange}
                    />

                    <WeightPicker
                      weight={values.weight}
                      measure={values.weight_unit}
                      error={
                        isSubmitted &&
                        typeof errors.weight === "string" &&
                        t(errors.weight)
                      }
                      onFieldChange={onFieldChange}
                    />
                  </div>

                  <div className="form-action-container">
                    <Button
                      icon={images.rightArrow}
                      clickHandler={() => {
                        handleSubmit();
                        setSubmitted(true);
                      }}
                      type={ButtonTypes.big}
                      isSubmit={true}
                      text={t("button_continue")}
                    />
                  </div>
                </>
              );
            }}
          </Formik>
          <div className="footer-container">
            <Footer />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Profile;

import React, { useEffect, useState } from "react";
import { useUser } from "modules/context/UserProvider";
import { useProfileQuery } from "modules/utils/hooks";
import apiCall, { Endpoints } from "modules/api";
import { config } from "modules/constants";
import styles from "./ThankYou.module.scss";
import Bugsnag from "@bugsnag/js";
import Button, { ButtonTypes } from "components/Button/Button";
import { images } from "modules/images";
import Footer from "components/Footer/Footer";
import { useTranslation } from "react-i18next";

const ThankYou: React.FunctionComponent = () => {
  const { data: profile } = useProfileQuery();
  const { t } = useTranslation("common");
  const { user } = useUser();

  const [tracked, setTracked] = useState(false);
  useEffect(() => {
    if (tracked || profile?.thank_you_screen_visited_at) return;
    if (!user?.token || !profile) return;
    const trackScreen = async () => {
      try {
        if (typeof user.token !== "string") throw new Error("token");

        const headers = {
          "Content-Type": "application/json",
          Authorization: user.token,
        };

        await apiCall.post(Endpoints.trackThankYou, {}, { headers });

        setTracked(true);
      } catch (error) {
        console.log(error);
        Bugsnag.notify(error);
      }
    };

    trackScreen();
  }, [profile, tracked, profile?.thank_you_screen_visited_at, user?.token]);

  const handleShareFeedback = () => {
    window.location.replace(config.feedbackUrl);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.centerContainer}>
        <h1 className={styles.heading}>{t("thank_you")}</h1>
        <div className={styles.infoContainer}>
          <h3>{t("congratulations")}</h3>
          <span>{t("thank_you_title")}</span>
          <br />
          <br />
          <span>{t("thank_you_text")}</span>
          <br />
          <a href={config.feedbackUrl || ""} target="_blank" rel="noreferrer">
            {config.feedbackUrl ? "www.clientwebsite/finalprice.com" : ""}
          </a>
        </div>
        <div className={styles.buttonContainer}>
          <Button
            type={ButtonTypes.big}
            text={t("share_feedback")}
            icon={images.rightArrow}
            clickHandler={handleShareFeedback}
          />
        </div>
        <div className={styles.buttonHelper}>{t("bottom_feedback")}</div>
        <div className={styles.bottomContainer}>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default ThankYou;

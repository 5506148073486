import React from "react";
import { images } from "modules/images";

import "./styles.scss";

const Pending = () => (
  <div className="pendingContainer">
    <div className="centerContainer">
      <img
        alt="activity indicator"
        className="pending-icon"
        src={images.pending}
      />
    </div>
  </div>
);

export default Pending;

import { FunctionComponent } from "react";
import styles from "./SecondaryButton.module.scss";

export interface ButtonProps {
  disabled?: boolean;
  text?: string;
  clickHandler?: () => void;
}

const SecondaryButton: FunctionComponent<ButtonProps> = ({
  text,
  disabled,
  clickHandler,
}) => (
  <div
    className={disabled ? styles.disabledWrapper : styles.wrapper}
    onClick={clickHandler}
  >
    <span className={styles.text}>{text}</span>
  </div>
);

export default SecondaryButton;

import React, { useEffect, useState } from "react";
import { Formik, FormikValues } from "formik";
import Bugsnag from "@bugsnag/js";
import * as Yup from "yup";
import { useUser } from "modules/context/UserProvider";
import TextInput from "components/TextInput/TextInput";
import { handlePreventDefault } from "modules/utils";
import apiCall, { Endpoints } from "modules/api";
import styles from "./ContactUs.module.scss";
import { images } from "modules/images";
import Button, { ButtonTypes } from "components/Button/Button";
import { ReactComponent as CloseButton } from "assets/images/icons/close.svg";
import { ReactComponent as AddButton } from "assets/images/icons/add.svg";
import { ReactComponent as BinButton } from "assets/images/icons/bin.svg";
import { ReactComponent as EmptyImage } from "assets/images/icons/emptyImage.svg";
import { useTranslation } from "react-i18next";

interface Props {
  onClose?: () => void;
}

const validationSchema: any = Yup.object().shape({
  email: Yup.string().email("error_email").required("error_required"),
  message: Yup.string()
    .min(20, "error_min_char")
    .max(200, "error_max_char")
    .required("error_required"),
});

const ContactUs: React.FunctionComponent<Props> = ({ onClose }) => {
  const { user } = useUser();
  const { t } = useTranslation("common");
  const [isSubmitted, setSubmitted] = useState(false);
  const [isSuccess, setSuccess] = useState<boolean | null>();
  const hiddenFileInput = React.useRef<HTMLInputElement>(null);
  const maxFilesNumber = 3;
  const [emptyFiles, setEmptyFiles] = useState(
    Array.from(Array(maxFilesNumber - 1).keys()),
  );
  const [emailInput, setEmailInput] = useState("abc@abc.abc");

  const handleClose = () => {
    setEmailInput("");
    onClose();
  };

  const [files, setFiles] = useState<
    {
      pictureAsFile: Blob;
      picturePreview: string;
    }[]
  >([]);

  const handleInputChange = (event: {
    currentTarget: { files: FileList | null };
  }) => {
    const value = event.currentTarget.files
      ? event.currentTarget.files[0]
      : null;

    if (value) {
      const picturePreview = URL.createObjectURL(value);
      const pictureAsFile = value;

      setFiles([...files, { picturePreview, pictureAsFile }]);
    }
  };

  const handleSubmit = async (data: FormikValues) => {
    setSubmitted(true);
    const { email, message } = data;

    let form = new FormData();

    const userInfo = {
      id: user.id,
      name: user.id,
      email,
    };
    const app = {
      page: "ContactUs",
      build: "Web application",
      version: "1.0.0",
    };

    const device = {
      id: "",
      os: "",
      type: "",
      screen: "",
      timezone: "",
    };

    const meta = {
      timestamp: Math.floor(Date.now() / 1000),
    };
    form.append("message", JSON.stringify(message));
    form.append("user", JSON.stringify(userInfo));
    form.append("device", JSON.stringify(device));
    form.append("app", JSON.stringify(app));
    form.append("meta", JSON.stringify(meta));
    files.forEach(({ pictureAsFile }) => form.append("files", pictureAsFile));

    try {
      await apiCall.post(Endpoints.feedback, form, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setEmailInput(email);
      setSuccess(true);
    } catch (error) {
      setSuccess(false);
      Bugsnag.notify(error);
    } finally {
      setSubmitted(false);
    }
  };

  const handleClick = () => hiddenFileInput?.current?.click();

  const remove = (index: number) =>
    setFiles(files.filter((_, i) => index !== i));

  useEffect(() => {
    const newEmptyArrLength =
      files.length === 0
        ? maxFilesNumber - 1
        : maxFilesNumber - files.length - 1;

    const newEmptyArr = Array.from(
      Array(newEmptyArrLength < 0 ? 0 : newEmptyArrLength).keys(),
    );

    setEmptyFiles(newEmptyArr);
  }, [emptyFiles.length, files.length]);

  return (
    <div
      className={styles.wrapper}
      onClick={handleClose}
      style={{ height: "100%" }}
    >
      <Formik
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        initialValues={{ email: "", message: "" }}
      >
        {({ values, errors, touched, handleSubmit, handleChange }) => (
          <>
            {isSuccess === undefined && (
              <div className={styles.container} onClick={handlePreventDefault}>
                <div className={styles.header}>
                  <div className={styles.title}>{t("contact_us")}</div>
                  <div className={styles.closeButtonContainer}>
                    <button
                      className={styles.closeButton}
                      onClick={handleClose}
                    >
                      <CloseButton />
                    </button>
                  </div>
                </div>
                <div className={styles.description}>
                  {t("contact_us_any_question")}
                  <br />
                  {t("contact_us_reply_24_h")}
                </div>

                <div className={styles.inputContainer}>
                  <TextInput
                    error={touched.email && !!errors.email}
                    errorText={
                      touched.email &&
                      typeof errors.email === "string" &&
                      t(errors.email)
                    }
                    label={t("contact_us_your_email")}
                    onChange={handleChange("email")}
                    placeholder={t("contact_us_your_email_placeholder")}
                    value={values.email}
                    width="45%"
                    required={true}
                  />
                </div>

                <div className={styles.inputContainer}>
                  <TextInput
                    error={touched.message && !!errors.message}
                    errorText={
                      touched.message &&
                      typeof errors.message === "string" &&
                      t(errors.message)
                    }
                    label={t("contact_us_your_message")}
                    multiline={true}
                    onChange={handleChange("message")}
                    placeholder={t("contact_us_your_message_placeholder")}
                    value={values.message}
                    width="100%"
                    required={true}
                  />
                </div>

                <div className={styles.inputContainer}>
                  <div className={styles.imagesInfo}>
                    {t("contact_us_attach_image")}
                  </div>
                  <div className={styles.images}>
                    {files.map((f, i) => (
                      <div
                        key={`image_${i}`}
                        onClick={() => remove(i)}
                        className={`${styles.addedImageContainer} ${
                          i + 1 === files.length &&
                          files.length === maxFilesNumber
                            ? ""
                            : styles.withMargin
                        }`}
                      >
                        <img
                          src={f?.picturePreview}
                          className={styles.addedImage}
                          alt=""
                        />

                        <div className={styles.removeIconContainer}>
                          <BinButton className={styles.removeIcon} />
                        </div>
                      </div>
                    ))}

                    {files.length < maxFilesNumber && (
                      <>
                        <button
                          onClick={handleClick}
                          className={`${styles.addButton} ${
                            maxFilesNumber - files.length === 1
                              ? ""
                              : styles.withMargin
                          }`}
                        >
                          <AddButton className={styles.addIcon} />
                          <span className={styles.addImageButtonText}>
                            {t("contact_us_upload")}
                          </span>
                          <span className={styles.addImageButtonTextBig}>
                            {t("contact_us_upload_image")}
                          </span>
                        </button>

                        <input
                          ref={hiddenFileInput}
                          accept=".jpg, .png, .jpeg"
                          type="file"
                          style={{ display: "none" }}
                          onChange={(e: React.FormEvent<HTMLInputElement>) =>
                            handleInputChange(e)
                          }
                          className=""
                        />
                      </>
                    )}

                    {emptyFiles.length > 0 &&
                      emptyFiles.map((el, index) => {
                        return (
                          <div
                            className={`${styles.emptyImageContainer} ${
                              index + 1 !== emptyFiles.length
                                ? styles.withMargin
                                : ""
                            }`}
                            key={index}
                          >
                            <EmptyImage className={styles.emptyImage} />
                          </div>
                        );
                      })}
                  </div>
                </div>

                <div className={styles.sendButtonContainer}>
                  <Button
                    type={ButtonTypes.big}
                    icon={images.send}
                    disabled={isSubmitted}
                    text={t("button_send")}
                    clickHandler={handleSubmit}
                  />
                </div>
              </div>
            )}
          </>
        )}
      </Formik>

      {isSuccess === true && (
        <div className={styles.resultContainer} onClick={handlePreventDefault}>
          <div className={styles.resultHeader}>
            <div className={styles.headerContainer}></div>
            <div className={styles.headerContainer}>
              <div className={styles.resultTitleSuccessDesktop}>
                {t("contact_us_thank_you")}
              </div>
            </div>
            <div
              className={`${styles.headerContainer} ${styles.headerCloseContainer}`}
            >
              <button
                className={styles.resultCloseButton}
                onClick={handleClose}
              >
                <CloseButton />
              </button>
            </div>
          </div>
          <div className={styles.resultContentContainer}>
            <div className={styles.resultTitleSuccessMobile}>
              {t("contact_us_thank_you")}
            </div>
            <div className={styles.resultDescription}>
              {t("contact_us_thank_you_text")}{" "}
              <a href="mailto:support@qumata.com" target="_top">
                support@qumata.com
              </a>
              <br />
              {t("contact_us_thank_you_text_2")}{" "}
              <span className={styles.emailSpan}>{emailInput}</span>
            </div>
            <div className={styles.resultButtonContainer}>
              <Button
                type={ButtonTypes.big}
                text={t("button_got_it")}
                icon={images.rightArrow}
                clickHandler={handleClose}
              />
            </div>
          </div>
        </div>
      )}

      {isSuccess === false && (
        <div className={styles.resultContainer} onClick={handlePreventDefault}>
          <div className={styles.resultHeaderFail}>
            <div className={styles.headerContainerFail}></div>
            <div className={styles.headerContainer}>
              <div className={styles.resultTitleSuccessDesktop}>
                {t("contact_us_failed")}
              </div>
            </div>
            <div
              className={`${styles.headerContainerFail} ${styles.headerCloseContainer}`}
            >
              <button
                className={styles.resultCloseButton}
                onClick={handleClose}
              >
                <CloseButton />
              </button>
            </div>
          </div>
          <div className={styles.resultContentContainerFail}>
            <div className={styles.resultTitleFailMobile}>
              {t("contact_us_not_delivered") + "!"}
            </div>
            <div className={styles.resultDescriptionFail}>
              {t("contact_us_not_delivered")}
              <br />
              {t("contact_us_please_try_again")}{" "}
              <a href="mailto:support@qumata.com" target="_top">
                support@qumata.com
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactUs;

import React from "react";
import { AccessStatus, DeviceId } from "modules/constants";
import { mapIdToDevice } from "screens/ConnectDevice/ConnectDevice";
import styles from "./DeviceHeader.module.scss";
import { images } from "modules/images";
import { useTranslation } from "react-i18next";

const borderedLogos = [
  DeviceId.apple,
  DeviceId.google,
  DeviceId.googleTimeline,
];

const ConnectedDeviceHeader = ({
  device,
  status,
}: {
  device: DeviceId;
  status?: AccessStatus;
}) => {
  const { t } = useTranslation("common");
  const renderStatus = () => {
    switch (true) {
      case status === AccessStatus.loading:
        return (
          <div className={styles.statusContainer}>
            <img src={images.loader} alt="Loading" className={styles.loader} />
            <span className={`${styles.status} ${styles.success}`}>
              {t("connecting_device")}
            </span>
          </div>
        );

      case status === AccessStatus.connected:
        return (
          <span className={`${styles.status} ${styles.success}`}>
            {t("data_uploaded_successfully")}
          </span>
        );

      case status === AccessStatus.collecting:
        return (
          <span className={`${styles.status} ${styles.success}`}>
            {t("connected_uploading_data")}
          </span>
        );

      case status === AccessStatus.notReceived:
        return (
          <span className={`${styles.status} ${styles.failure}`}>
            {t("consent_not_received")}
          </span>
        );
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.logoContainer}>
        <img
          src={mapIdToDevice[device].logo}
          alt="Logo"
          className={`${styles.logo} ${
            borderedLogos.includes(device) ? styles.bordered : ""
          }`}
        />
      </div>

      <div
        className={`${styles.titleContainer} ${
          status ? styles.between : styles.center
        }`}
      >
        <div className={styles.deviceTitle}>
          {t(mapIdToDevice[device].connectionTitle)}
        </div>
        <div className={styles.status}>{status && renderStatus()}</div>
      </div>
    </div>
  );
};

export default ConnectedDeviceHeader;

import { FunctionComponent } from "react";
import styles from "./UnableToConnect.module.scss";
import { useTranslation } from "react-i18next";

export const unableToConnectModalTitle = "unable_to_connect_title";

const UnableToConnectModalContent: FunctionComponent = () => {
  const { t } = useTranslation("common");
  return (
    <div className={styles.container}>
      <div className={styles.info}>
        {t("error_unable_to_connect_1")}
        <br />
        <br />
        {t("error_unable_to_connect_2")}
      </div>
    </div>
  );
};

export default UnableToConnectModalContent;

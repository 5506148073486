import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
  Policy,
  useProfileMutation,
  useProfileQuery,
} from "modules/utils/hooks";
import CheckBox from "components/Checkbox";
import { useApp } from "modules/context/AppProvider";
import Pending from "components/Pending";
import Layout from "components/Layout/Layout";
import { useUser } from "modules/context/UserProvider";
import { RoutesPaths } from "modules/routes";
import styles from "./SignIn.module.scss";
import { externalURLs } from "modules/constants";
import { images } from "modules/images";
import Button, { ButtonTypes } from "components/Button/Button";
import Footer from "components/Footer/Footer";
import DefaultErrorModalContent, {
  defaultErrorModalTitle,
} from "components/Modals/DefaultError/DefaultError";
import { useTranslation } from "react-i18next";

const SignInScreen: React.FunctionComponent<{}> = () => {
  const { data: profile } = useProfileQuery();
  const { mutate } = useProfileMutation();
  const navigate = useNavigate();
  const { user } = useUser();
  const { showAlert } = useApp();
  const { t } = useTranslation("common");
  const [pending, setPending] = useState(true);
  const [error, setError] = useState(false);

  const [policies, setPolicy] = useState([]);

  const canSubmit = policies?.length === 2;
  const isChecked = (policy: Policy) => policies.includes(policy);

  useEffect(() => {
    if (user && profile) {
      setPolicy(profile.policies);
      setPending(false);
    }
  }, [user, profile]);

  const updatePolicy = (policy: Policy): void => {
    const checked = policies.includes(policy);

    const data = checked
      ? policies.filter((item) => item !== policy)
      : [...policies, policy];

    if (data.length === 2) setError(false);

    setPolicy(data);
  };

  const savePolicies = async (policies: string[]) => {
    try {
      setPending(true);

      await mutate({
        policies: [Policy.privacy, Policy.terms],
      });

      setPending(false);
      navigate(RoutesPaths.chooseDevices);
    } catch (error) {
      console.log(error);
      showAlert(defaultErrorModalTitle, <DefaultErrorModalContent />);
    }
  };

  const submit = async () => {
    if (canSubmit) {
      await savePolicies(policies);
    } else {
      setError(true);
    }
  };

  return (
    <Layout progressBarPercent={20}>
      <div className={styles.centerContainer}>
        <div className={styles.wrapper}>
          {pending ? (
            <Pending />
          ) : (
            <>
              <div className={styles.title}>{t("sign_in_privacy_policy")}</div>

              <div className={styles.info}>{t("sign_in_info")}</div>
              <div className={styles.checboxesWrapper}>
                <div
                  className={`${styles.checkboxContainer} ${styles.firstCheckbox}`}
                >
                  <CheckBox
                    value="privacy"
                    checked={isChecked(Policy.privacy)}
                    onChange={() => updatePolicy(Policy.privacy)}
                    error={error && !isChecked(Policy.privacy)}
                    label={
                      <span>
                        {t("sign_in_I_agree")}
                        <a
                          href={externalURLs.privacyPolicy}
                          target="_blank"
                          rel="noreferrer"
                          className={styles.link}
                        >
                          {t("sign_in_data_privacy")}
                        </a>
                      </span>
                    }
                  />
                </div>
                <div className={styles.checkboxContainer}>
                  <CheckBox
                    value="terms"
                    checked={isChecked(Policy.terms)}
                    onChange={() => updatePolicy(Policy.terms)}
                    error={error && !isChecked(Policy.terms)}
                    label={
                      <span>
                        {t("sign_in_I_agree")}

                        <a
                          href={externalURLs.termsAndConditions}
                          target="_blank"
                          rel="noreferrer"
                          className={styles.link}
                        >
                          {t("sign_in_terms_and_conditions")}
                        </a>
                      </span>
                    }
                  />
                </div>
              </div>
              {error && (
                <div className={styles.warningContainer}>
                  <div>
                    <img
                      src={images.warning}
                      alt="Warning"
                      className={styles.warningIcon}
                    />
                  </div>
                  <div className={styles.warningText}>
                    {t("sign_in_warning_text")}
                  </div>
                </div>
              )}

              <div className={styles.buttonContainer}>
                <Button
                  type={ButtonTypes.big}
                  text={t("button_continue")}
                  icon={images.rightArrow}
                  clickHandler={submit}
                />
              </div>

              <div className={styles.footerContainer}>
                <Footer />
              </div>
            </>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default SignInScreen;

import React from "react";

import ConnectDeviceScreen from "screens/ConnectDevice/ConnectDevice";
import { DeviceId } from "modules/constants";

const ConnectGarmin: React.FunctionComponent<{}> = () => (
  <ConnectDeviceScreen deviceId={DeviceId.garmin} />
);

export default ConnectGarmin;

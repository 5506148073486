import ButtonContent from "components/ButtonContent/ButtonContent";
import { FunctionComponent } from "react";
import styles from "./Button.module.scss";

export enum ButtonTypes {
  small = "small",
  big = "big",
  back = "back",
  secondary = "secondary",
  text = "text",

  // this button type is used only on Apple Health connection
  // screen for mobile devices on button with text "I Opened The App"
  exception = "exception",
}

export interface ButtonProps {
  type: ButtonTypes;
  text: string;
  icon?: string;
  disabled?: boolean;
  isSubmit?: boolean;
}

const Button: FunctionComponent<{
  type: ButtonTypes;
  disabled?: boolean;
  text?: string;
  icon?: string;
  clickHandler?: () => void;
  isSubmit?: boolean;
  style?: string;
}> = ({ type, disabled, text, icon, clickHandler, isSubmit, style }) => (
  <button
    className={`
        ${
          type === ButtonTypes.back
            ? styles.backButtonWrapper
            : disabled
            ? styles.disabledWrapper
            : styles.wrapper
        } ${type === ButtonTypes.big ? styles.big : styles.small} ${
      style ?? ""
    }`}
    onClick={clickHandler}
  >
    <ButtonContent type={type} text={text} icon={icon} disabled={disabled} />
  </button>
);

export default Button;

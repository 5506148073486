import { useLocation } from "react-router";
import styles from "./Header.module.scss";
import HomeButton from "components/HomeButton/HomeButton";
import HeaderLogo from "components/HeaderLogo/HeaderLogo";
import ContactUsButton from "components/ContactUsButton";
import { RoutesPaths } from "modules/routes";
import { useApp } from "../../modules/context/AppProvider";

const Header = () => {
  const location = useLocation();
  const { pathname } = location;
  const { currentLanguage, changeLanguage, showLanguagePopup, languagePopup } =
    useApp();

  return (
    <div className={styles.headerWrapper}>
      <div className={styles.container}>
        <span>{pathname !== RoutesPaths.home && <HomeButton />}</span>
      </div>

      <div className={styles.container}>
        <span>
          <HeaderLogo />
        </span>
      </div>

      <div className={styles.container}>
        <div className={styles.rightContainer}>
          <div className={styles.currentLanguage}>
            <div
              className={styles.selectedLanguage}
              onClick={() => showLanguagePopup(true)}
            >
              {currentLanguage.toUpperCase()}
            </div>

            {languagePopup && (
              <div
                className={styles.languageContainer}
                onMouseLeave={() => showLanguagePopup(false)}
              >
                <button
                  className={styles.languageButton}
                  type="button"
                  onClick={() => {
                    showLanguagePopup(false);
                    changeLanguage("en");
                  }}
                >
                  EN
                </button>
                <button
                  className={styles.languageButton}
                  type="button"
                  onClick={() => {
                    showLanguagePopup(false);
                    changeLanguage("ua");
                  }}
                >
                  UA
                </button>
              </div>
            )}
          </div>
          <ContactUsButton />
        </div>
      </div>

      {languagePopup && (
        <div
          onClick={() => showLanguagePopup(false)}
          className={styles.languageWrapper}
        />
      )}
    </div>
  );
};

export default Header;

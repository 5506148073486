import { FunctionComponent } from "react";
import styles from "./SmallButton.module.scss";

interface ButtonProps {
  text: string;
  icon?: string;
  disabled?: boolean;
}

const SmallButton: FunctionComponent<ButtonProps> = ({
  text,
  icon,
  disabled,
}) => {
  return (
    <div className={`${styles.wrapper} ${disabled && styles.disabled}`}>
      {text && <span className={`${styles.text}`}>{text}</span>}
      {icon && (
        <div className={styles.iconContainer}>
          <img src={icon} alt="Icon" />
        </div>
      )}
    </div>
  );
};

export default SmallButton;

import React from "react";
import "./styles.scss";
import { useTranslation } from "react-i18next";

const NotFound: React.FunctionComponent = () => {
  const { t } = useTranslation("common");

  return (
    <div className="not-found-container">
      <div className="form-wrapper">
        <div className="content-container">
          <div className="header">404</div>
          <div className="content">
            {t("not_found")}
            <a href="mailto:contact@qumata.com" target="_top">
              contact@qumata.com
            </a>
            .
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;

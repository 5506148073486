import React, { useState } from "react";
import { RoutesPaths } from "modules/routes";
import { useNavigate } from "react-router-dom";
import AboutDataCollection from "components/Modals/AboutDataCollection";
import { useCollectionStatusQuery } from "modules/utils/hooks";
import RevokeConsentModalContent, {
  revokeConsentModalTitle,
} from "components/Modals/RevokeConsent/RevokeConsent";
import Layout from "components/Layout/Layout";
import { apps, devices } from "modules/devices";
import DeviceItem from "./DeviceItem";
import styles from "./ChooseDevices.module.scss";
import Button, { ButtonTypes } from "components/Button/Button";
import { images } from "modules/images";
import SecondaryButton from "components/SecondaryButton/SecondaryButton";
import Footer from "components/Footer/Footer";
import { useApp } from "modules/context/AppProvider";
import AppleHealthTutorial from "components/Modals/AppleHealthTutorial/AppleHealthTutorial";
import { useTranslation } from "react-i18next";

const ChooseDevices: React.FunctionComponent<{}> = () => {
  const navigate = useNavigate();
  const { data, refetch, isFetching, isLoading } = useCollectionStatusQuery();
  const { showAlert } = useApp();
  const { t } = useTranslation("common");
  const [appleRevokeModalOpened, setAppleRevokeModalOpened] =
    useState<boolean>(false);

  const isGoogleTimelineUploaded = !!localStorage.getItem("@GoogleTimeline");
  const isConnected =
    (data && Object.values(data).some((i) => i.is_receiving_data)) ||
    isGoogleTimelineUploaded;
  const hasEnoughData =
    (data && Object.values(data).some((i) => i.has_enough_data)) ||
    isGoogleTimelineUploaded;

  const [showDataConnectionInfo, toggleDataConnectionInfo] =
    React.useState(false);

  const openAppleRevokeModal = () => setAppleRevokeModalOpened(true);

  const closeAppleRevokeModal = () => setAppleRevokeModalOpened(false);

  const openRevokeConsentModal = (device) =>
    showAlert(
      t(revokeConsentModalTitle),
      <RevokeConsentModalContent
        device={device}
        callback={refetch}
        appleHandler={openAppleRevokeModal}
      />,
    );

  const handleNext = (route) => navigate(route);
  const isDisabled =
    isLoading || isFetching || (!hasEnoughData && !isConnected);

  return (
    <>
      {!appleRevokeModalOpened && (
        <Layout progressBarPercent={40}>
          <div className={styles.wrapper}>
            <div className={styles.centerContainer}>
              <div className={styles.title}>{t("choose_devices_title")}</div>

              <div className={styles.info}>{t("choose_devices_info")}</div>

              <div className={styles.devicesContainer}>
                <div className={styles.appsBlock}>
                  <div className={styles.blockTitle}>Health Apps</div>
                  <div className={styles.itemsContainer}>
                    {apps.map((device) => {
                      const status = data?.find((i) => i.name === device.name);

                      return (
                        <div
                          className={styles.deviceContainer}
                          key={device.title}
                        >
                          <DeviceItem
                            key={device.title}
                            device={device}
                            isLoading={isLoading || isFetching}
                            handleNext={handleNext}
                            openRevokeModal={openRevokeConsentModal}
                            status={status}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className={styles.devicesBlock}>
                  <div className={styles.blockTitle}>
                    {t("tracking_device")}
                  </div>

                  {devices.map((device, index) => {
                    const status = data?.find((i) => i.name === device.name);

                    return (
                      <div
                        className={styles.deviceContainer}
                        key={device.title + index.toString()}
                      >
                        <DeviceItem
                          device={device}
                          isLoading={isLoading || isFetching}
                          handleNext={handleNext}
                          openRevokeModal={openRevokeConsentModal}
                          status={status}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className={styles.buttonsContainer}>
                <Button
                  type={ButtonTypes.big}
                  text={t("button_continue")}
                  icon={images.rightArrow}
                  disabled={isDisabled}
                  clickHandler={() => navigate(RoutesPaths.profile)}
                />

                <div className={styles.secondaryButtonWrapper}>
                  <SecondaryButton
                    text={t("find_out_more")}
                    clickHandler={() => toggleDataConnectionInfo(true)}
                  />
                </div>
              </div>

              <div className={styles.footerContainer}>
                <Footer />
              </div>
            </div>

            {showDataConnectionInfo && (
              <AboutDataCollection
                onClose={() => toggleDataConnectionInfo(false)}
              />
            )}
          </div>
        </Layout>
      )}

      {appleRevokeModalOpened && (
        <AppleHealthTutorial onClose={closeAppleRevokeModal} />
      )}
    </>
  );
};

export default ChooseDevices;

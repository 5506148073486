import React, { ReactElement } from "react";
import classNames from "classnames";
import { FormikErrors } from "formik";

import "./field.scss";

type FormFieldProps = {
  title?: string;
  error?: string | string[] | FormikErrors<any> | FormikErrors<any>[];
  className?: string;
  measure?: string;
  children: ReactElement;
};

const FormField: React.FC<FormFieldProps> = ({
  title,
  error,
  children,
  className,
}) => {
  const fieldClass = classNames("form-field", className, {
    "field-error": error,
  });

  return (
    <div className={fieldClass}>
      {title && (
        <p className="field-title">
          {title}
          <span className="required-mark">*</span>
        </p>
      )}
      {children}
      <p className="field-disclaimer">
        {typeof error === "string" ? error : " "}
      </p>
    </div>
  );
};

export default FormField;

import React, { useState } from "react";
// import { ReactQueryDevtools } from "react-query/devtools"; // Develop help tool to react-query
// import { hotjar } from "react-hotjar";
import branch from "branch-sdk";
import { QueryClient, QueryClientProvider } from "react-query";
import AppProvider from "./modules/context/AppProvider";
import UserProvider from "./modules/context/UserProvider";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { config } from "modules/constants";
import "./normalize.css";
import "./App.css";
import AppNavigator from "./AppNavigator";
import ContactUs from "components/Modals/ContactUs/ContactUs";
import DeviceProvider from "modules/context/DeviceProvider";
import RedirectProvider from "modules/context/RedirectProvider";
import { BrowserRouter } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import { resources } from "./modules/locales/resources";
// hotjar.initialize(parseInt(config.hjid), parseInt(config.hjsv));
branch.init(config.branchKey);

Bugsnag.start({
  apiKey: config.bugsnagId,
  plugins: [new BugsnagPluginReact()],
});

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

i18next.init({ lng: "en", resources });

const App: React.FunctionComponent<{}> = () => {
  const [showContactUs, toggleContactUs] = useState(false);

  return (
    <QueryClientProvider client={queryClient}>
      <ErrorBoundary>
        <UserProvider>
          <RedirectProvider>
            <DeviceProvider>
              <BrowserRouter>
                <I18nextProvider i18n={i18next}>
                  <AppProvider>
                    <>
                      <AppNavigator />
                      {/* Develop help tool to react-query */}
                      {/* <ReactQueryDevtools initialIsOpen={false} /> */}

                      {showContactUs && (
                        <ContactUs onClose={() => toggleContactUs(false)} />
                      )}
                    </>
                  </AppProvider>
                </I18nextProvider>
              </BrowserRouter>
            </DeviceProvider>
          </RedirectProvider>
        </UserProvider>
      </ErrorBoundary>
    </QueryClientProvider>
  );
};

export default App;

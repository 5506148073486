import React, { useState } from "react";
import QRCode from "qrcode.react";
import { useUser } from "modules/context/UserProvider";
import { RoutesPaths } from "modules/routes";
import Layout from "components/Layout/Layout";
import { useNavigate } from "react-router-dom";
import Pending from "components/Pending";
import { Endpoints } from "modules/api";
import { config, DeviceId } from "modules/constants";
import { images } from "modules/images";
import styles from "./ConnectAppleHealth.module.scss";
import DeviceHeader from "components/DeviceHeader/DeviceHeader";
import Button, { ButtonTypes } from "components/Button/Button";
import SecondaryButton from "components/SecondaryButton/SecondaryButton";
import Footer from "components/Footer/Footer";
import { useTranslation } from "react-i18next";

const ConnectAppleHealth: React.FunctionComponent<{}> = () => {
  const { user } = useUser();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [deeplink, setDeepLink] = useState("");
  const { t } = useTranslation("common");

  React.useEffect(() => {
    if (!deeplink && user && !loading) {
      const requestDeeplink = async (): Promise<void> => {
        try {
          if (typeof user.token !== "string") throw new Error("token");
          setLoading(true);

          const url = `${config.api}${Endpoints.deeplink}`;

          const headers = {
            Authorization: user.token,
            "Content-Type": "application/json",
          };

          const response = await fetch(url, { headers });
          const res = await response.json();

          setDeepLink(res.deeplink.toString());
          setLoading(false);
        } catch (error) {
          setLoading(false);
          console.log(error);
        }
      };

      requestDeeplink();
    }
  }, [deeplink, loading, user]);

  const homeRedirectHandler = () => navigate(RoutesPaths.home);

  return (
    <Layout progressBarPercent={40}>
      <div className={styles.wrapper}>
        <div className={styles.centerContainer}>
          <div className={styles.headerContainer}>
            <DeviceHeader device={DeviceId.apple} />
          </div>
          <div className={styles.infoContainer}>
            <div className={styles.description}>
              {t("connect_apple_health_description_1")}
            </div>

            <br />
            <br />

            <div className={`${styles.description} ${styles.qr}`}>
              {t("connect_apple_health_description_2")}
            </div>

            <div className={`${styles.description} ${styles.deeplink}`}>
              {t("connect_apple_health_description_3")}
            </div>

            <div>
              {loading ? (
                <Pending />
              ) : (
                <div className={styles.deeplinkContainer}>
                  <div className={styles.qr}>
                    {!!deeplink && <QRCode value={deeplink} size={178} />}
                  </div>
                  <div className={styles.smallButtons}>
                    <div className={styles.buttonContainer}>
                      <Button
                        type={ButtonTypes.big}
                        text={t("download_the_app")}
                        icon={images.rightArrow}
                        clickHandler={() => window.open(deeplink, "_blank")}
                      />
                    </div>

                    <SecondaryButton
                      text={t("try_another_source")}
                      clickHandler={() => navigate(RoutesPaths.chooseDevices)}
                    />
                  </div>
                </div>
              )}
            </div>

            <div className={styles.description}>
              {t("connect_apple_health_description_4")}
            </div>

            <div className={styles.bigButtons}>
              <div className={styles.bigButtonContainer}>
                <Button
                  type={ButtonTypes.big}
                  text={t("i_opened_the_app")}
                  clickHandler={homeRedirectHandler}
                />
              </div>

              <SecondaryButton
                text={t("try_another_source")}
                clickHandler={() => navigate(RoutesPaths.chooseDevices)}
              />
            </div>

            {user?.redirect && (
              <div className={styles.alertContainer}>
                <div className={styles.alertTitle}>
                  {t("connect_apple_health_alert_title")}
                </div>
                <div className={styles.alertMessage}>
                  {t("connect_apple_health_alert_message")}
                </div>
              </div>
            )}

            <div className={`${styles.deeplink} ${styles.extButtonContainer}`}>
              <button
                className={styles.openedButton}
                onClick={homeRedirectHandler}
              >
                {t("i_opened_the_app")}
              </button>
            </div>
          </div>
        </div>

        <div className={styles.footerContainer}>
          <Footer />
        </div>
      </div>
    </Layout>
  );
};

export default ConnectAppleHealth;

import React, { FunctionComponent } from "react";
import { FormikErrors } from "formik";
import styles from "./FormField.module.scss";

type FormFieldProps = {
  title?: string;
  error?: string | string[] | FormikErrors<any> | FormikErrors<any>[];
  measure?: string;
  required?: boolean;
};

const FormField: FunctionComponent<FormFieldProps> = ({
  title,
  children,
  required,
}) => {
  return (
    <div className={styles.wrapper}>
      {title && (
        <h2 className={styles.title}>
          {title}
          {required ? <span className={styles.mark}>*</span> : ""}
        </h2>
      )}
      {children}
    </div>
  );
};

export default FormField;

import React from "react";
import Select from "react-select";
import { FormikErrors } from "formik";
import { Measure } from "modules/utils/hooks";
import { InputField } from "./Profile";
import { range, split } from "modules/helper";
import FormField from "./Field";
import { useTranslation } from "react-i18next";

type BaseHeightPickerProps = {
  height: string;
  measure: Measure;
  error?: string | string[] | FormikErrors<any> | FormikErrors<any>[];
  onFieldChange: (field: InputField, value: string | number) => void;
};

export const MIN_CM = 60;
export const MAX_CM = 303;
const CM_RANGE = Array.from(range(MIN_CM, MAX_CM, 1)).map((item) => ({
  label: item.toString(),
  value: item.toString(),
}));

const MIN_MM = 0.0;
const MAX_MM = 0.9;
const MM_RANGE = Array.from(range(MIN_MM, MAX_MM, 0.1))
  .map((i: number) => parseFloat(i.toFixed(1)))
  .map((item) => ({
    label: item.toString(),
    value: item.toString(),
  }));

const MIN_FEET = 2;
const MAX_FEET = 10;
const FEET_RANGE = Array.from(range(MIN_FEET, MAX_FEET, 1)).map((item) => ({
  label: item.toString(),
  value: item.toString(),
}));

const MIN_INCH = 0;
const MAX_INCH = 12;
const INCH_RANGE = Array.from(range(MIN_INCH, MAX_INCH, 1)).map((item) => ({
  label: item.toString(),
  value: item.toString(),
}));

const HeightPicker: React.FunctionComponent<BaseHeightPickerProps> = ({
  height,
  error,
  onFieldChange,
  measure = Measure.Centimeters,
}) => {
  const isImperial = measure === Measure.Feet;
  const { integral, fraction } = split(height, isImperial);
  const { t } = useTranslation("common");

  const heightUnitsRage = [
    { label: t("profile_centimeters"), value: Measure.Centimeters },
    { label: t("profile_feet"), value: Measure.Feet },
  ];

  const onNumberChange = (newNumber) =>
    isImperial
      ? onFieldChange("height", newNumber + "." + fraction)
      : onFieldChange(
          "height",
          (parseInt(newNumber) + parseFloat(fraction)).toFixed(1),
        );

  const onDecimalChange = (newDecimal) =>
    isImperial
      ? onFieldChange("height", integral + "." + newDecimal)
      : onFieldChange(
          "height",
          (parseInt(integral) + parseFloat(newDecimal)).toFixed(1),
        );

  const fractionRange = isImperial ? FEET_RANGE : CM_RANGE;
  const fractionValue = fractionRange.find((item) => item.value === integral);

  const decimalRange = isImperial ? INCH_RANGE : MM_RANGE;
  const decimalValue = decimalRange.find((item) => item.value === fraction);

  const currentValue = heightUnitsRage.find((item) => item.value === measure);

  return (
    <div className="height-picker-container">
      <FormField title="Height unit" className="measure-unit">
        <Select
          key={currentValue.label}
          placeholder={t("placeholder_select")}
          classNamePrefix="dropdown"
          defaultValue={currentValue}
          isSearchable={false}
          options={heightUnitsRage}
          onChange={(e) => {
            onFieldChange("height", undefined);
            onFieldChange("height_unit", e.value);
          }}
        />
      </FormField>

      <FormField title="Height" error={error} className="measure-selector">
        <div className="flex-row">
          <Select
            key={fractionValue?.label}
            placeholder={t("placeholder_select")}
            isSearchable={false}
            options={fractionRange}
            classNamePrefix="dropdown"
            defaultValue={fractionValue}
            onChange={(e) => onNumberChange(e.value)}
          />
          <div className="measure-item">{isImperial ? t("ft") : t("cm")}</div>
          {!!height && (
            <>
              <Select
                key={decimalValue?.label}
                placeholder={t("placeholder_select")}
                isSearchable={false}
                options={decimalRange}
                classNamePrefix="dropdown"
                defaultValue={decimalValue}
                onChange={(e) => onDecimalChange(e.value)}
              />
              <div className="decimal-item">
                {isImperial ? t("in") : t("cm")}
              </div>
            </>
          )}
        </div>
      </FormField>
    </div>
  );
};

export default HeightPicker;

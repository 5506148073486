import { FunctionComponent } from "react";
import Switch from "components/Switch/Switch";
import styles from "./Condition.module.scss";

type ConditionProps = {
  label: string;
  checked: boolean;
  index: number;
  clickHandler: () => void;
};

const Condition: FunctionComponent<ConditionProps> = ({
  label,
  checked,
  index,
  clickHandler,
}) => {
  return (
    <div
      className={`${styles.wrapper} ${index === 0 ? styles.first : ""}`}
      onClick={clickHandler}
    >
      <div className={styles.label}>{label}</div>

      <Switch checked={checked} />
    </div>
  );
};

export default Condition;

import React from "react";
import { DeviceDataInterface } from "./DeviceItem";
import styles from "./DeviceItemStatus.module.scss";
import { useTranslation } from "react-i18next";

const DeviceItemStatus: React.FunctionComponent<{
  status: DeviceDataInterface;
  hasStatus: boolean;
  isLoading: boolean;
  isGoogleTimeline: boolean;
}> = ({ status, hasStatus, isLoading, isGoogleTimeline }) => {
  const { t } = useTranslation("common");

  if (!status || !hasStatus || isLoading) {
    return null;
  }

  const isGoogleTimelineConnected =
    isGoogleTimeline && !!localStorage.getItem("@GoogleTimeline");
  const getStatus = () => {
    switch (true) {
      case isGoogleTimelineConnected && status.connected:
        return (
          <div className={`${styles.status} ${styles.connected}`}>
            {t("device_item_status_uploaded")}
          </div>
        );

      case isGoogleTimelineConnected:
        return (
          <div className={`${styles.status} ${styles.noConsent}`}>
            {t("device_item_status_not_enough_data")}
          </div>
        );

      case status.is_receiving_data && status.has_enough_data:
        return (
          <div className={`${styles.status} ${styles.connected}`}>
            {t("device_item_status_connected")}
          </div>
        );

      case status.is_receiving_data:
        return (
          <div className={`${styles.status} ${styles.uploading}`}>
            {t("device_item_status_uploading")}
          </div>
        );

      case !status.is_receiving_data:
        return (
          <div className={`${styles.status} ${styles.noConsent}`}>
            {t("device_item_status_no_consent")}
          </div>
        );

      default:
        return <div className={styles.status}>""</div>;
    }
  };

  return <div className="status-container">{getStatus()}</div>;
};

export default DeviceItemStatus;

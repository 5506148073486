import { images } from "modules/images";
import { FunctionComponent } from "react";
import styles from "./StartButton.module.scss";

interface ButtonProps {
  text: string;
  disabled?: boolean;
  clickHandler?: () => void;
}

const StartButton: FunctionComponent<ButtonProps> = ({
  text,
  disabled,
  clickHandler,
}) => {
  return (
    <button
      className={`${disabled ? styles.disabledWrapper : styles.buttonWrapper} ${
        styles.start
      }`}
      onClick={clickHandler}
    >
      <div className={`${styles.wrapper} ${disabled ? styles.disabled : ""}`}>
        {text && <span className={styles.text}>{text}</span>}
        <div className={styles.iconContainer}>
          <img src={images.rightArrow} alt="Icon" />
        </div>
      </div>
    </button>
  );
};

export default StartButton;

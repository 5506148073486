import { FunctionComponent } from "react";
import styles from "./Switch.module.scss";

type SwitchProps = {
  checked: boolean;
};

const Switch: FunctionComponent<SwitchProps> = ({ checked }) => {
  return (
    <div
      className={`${styles.container} ${
        checked === true ? styles.connected : ""
      }`}
    >
      <div
        className={`${styles.switch} ${
          checked === true ? styles.connected : ""
        }`}
      ></div>
    </div>
  );
};

export default Switch;

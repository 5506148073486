import { FunctionComponent } from "react";
import styles from "./SelectConditions.module.scss";
import { useTranslation } from "react-i18next";

export const selectConditionsModalTitle = "form_incomplete";

const SelectConditionsModalContent: FunctionComponent = () => {
  const { t } = useTranslation("common");

  return (
    <div className={styles.container}>
      <div className={styles.info}>{t("select_conditions_modal_text")}</div>
    </div>
  );
};

export default SelectConditionsModalContent;

import React from "react";
import DeviceHeader from "components/DeviceHeader/DeviceHeader";
import styles from "./StartConnectDevice.module.scss";
import { DeviceId } from "modules/constants";
import Button, { ButtonTypes } from "components/Button/Button";
import Footer from "components/Footer/Footer";
import SecondaryButton from "components/SecondaryButton/SecondaryButton";
import { images } from "modules/images";
import { useNavigate } from "react-router-dom";
import { RoutesPaths } from "modules/routes";
import { mapIdToDevice } from "screens/ConnectDevice/ConnectDevice";
import { useTranslation } from "react-i18next";

type StartConnectDeviceProps = {
  device: DeviceId;
  onConnect: () => void;
  isLoading: boolean;
};

const StartConnectDevice: React.FunctionComponent<StartConnectDeviceProps> = ({
  device,
  onConnect,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation("common");

  return (
    <div className={styles.wrapper}>
      <div className={styles.centerContainer}>
        <div className={styles.headerContainer}>
          <DeviceHeader device={device} />
        </div>

        <div className={styles.infoContainer}>
          {t("start_connect_device_text_1")
            .replace("#{text1}", mapIdToDevice[device].title)
            .replace("#{text2}", mapIdToDevice[device].title)}
          <br />
          <br />
          {t("start_connect_device_text_2")
            .replace("#{text}", mapIdToDevice[device].title)
            .replace("#{text}", mapIdToDevice[device].title)}
        </div>

        <div className={styles.bottomContainer}>
          <div className={styles.buttonsContainer}>
            <Button
              type={ButtonTypes.big}
              text={t("button_sign_in")}
              icon={images.rightArrow}
              clickHandler={onConnect}
            />

            <div className={styles.cancelButtonContainer}>
              <SecondaryButton
                text={t("button_cancel")}
                clickHandler={() => navigate(RoutesPaths.chooseDevices)}
              />
            </div>
          </div>

          <div className={styles.footerContainer}>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StartConnectDevice;

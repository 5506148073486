import { FunctionComponent } from "react";
import FormField from "components/FormField/FormField";
import { FormikErrors } from "formik";
import { RadioOptionsType } from "modules/helper";
import styles from "./RadioPicker.module.scss";

interface RadioPickerInterface {
  title: string;
  name: string;
  options: RadioOptionsType[];
  currentValue: string | undefined;
  setFieldValue: (field: string, value: string) => void;
  error?: string | FormikErrors<any> | string[] | FormikErrors<any>[];
}

const RadioPicker: FunctionComponent<RadioPickerInterface> = ({
  title,
  name,
  options,
  currentValue,
  setFieldValue,
  error,
}) => {
  return (
    <FormField title={title} error={error} required={true}>
      <div className={styles.wrapper}>
        {options.map(({ label, value, additionalInfo }, index) => (
          <label
            className={`${styles.item} ${index === 0 ? styles.first : ""}`}
            key={label}
          >
            <input
              type="radio"
              name={name}
              value={value}
              checked={value === currentValue}
              onChange={() => setFieldValue(name, value)}
            />
            <p className={styles.label}>
              {label}
              {additionalInfo && (
                <span className={styles.additionalInfo}>{additionalInfo}</span>
              )}
            </p>
          </label>
        ))}
      </div>
      {/* <InputError error={error} /> */}
    </FormField>
  );
};

export default RadioPicker;

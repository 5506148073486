import React from "react";

import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "./style.module.scss";
import { images } from "../../modules/images";
import Button, { ButtonTypes } from "../../components/Button/Button";
import { config, DeviceId, TAKEOUT_GOOGLE_URL } from "../../modules/constants";
import { RoutesPaths } from "../../modules/routes";
import { Results } from "./index";
import ProgressBar from "../../components/ProgressBar";
import { useUser } from "../../modules/context/UserProvider";
import { Endpoints } from "../../modules/api";
import { useMediaQuery } from "../../modules/useMediaquery";

const StepImage = ({
  deskImage,
  mobImage,
}: {
  deskImage?: string;
  mobImage?: string;
}) => (
  <>
    {!!deskImage && (
      <img src={deskImage} className={styles.deskImage} alt="tutorial step" />
    )}
    {!!mobImage && (
      <img src={mobImage} className={styles.mobImage} alt="tutorial step" />
    )}
  </>
);

const StepHeader = ({ step, title }: { step: number; title: string }) => (
  <div className={styles.stepHeader}>
    <h3>{step}</h3>
    <p>{title}</p>
  </div>
);

const TutorialGoogleTimeline = ({
  setResult,
}: {
  setResult: (r: Results) => void;
  status: Results;
}) => {
  const { user } = useUser();
  const navigate = useNavigate();

  const [fileDropError, setFileDropError] = React.useState("");
  const [selectedFile, setFile] = React.useState<File | null>();
  const [isLoading, setLoading] = React.useState(false);
  const [tried, setTried] = React.useState<boolean | null>();
  const { t } = useTranslation("common");
  const isOverMd = useMediaQuery("(min-width: 768px)");

  // Create a reference to the hidden file input element
  const hiddenFileInput = React.useRef(null);

  const handleClick = () => hiddenFileInput.current.click();
  const handleFile = (file) => {
    const filesize = file.size / 1024 / 1024; // MB
    setFileDropError(null);

    if (filesize > 100) {
      setFileDropError("Please provide an image file to upload!");
    }

    if (file.type !== "application/zip") {
      setFileDropError(
        "This format is not supported, please delete and upload zip file.",
      );
    }

    setFile(file);
  };

  const handleChange = async (event) => {
    const selectedFile = event.target.files[0];

    handleFile(selectedFile);
  };

  const onDrop = (e: React.DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
    e.stopPropagation();

    handleFile(e?.dataTransfer?.files[0]);
  };

  const dragStart = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const dragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const removeFile = () => {
    hiddenFileInput.current.value = "";
    setFile(null);
  };

  React.useEffect(() => {
    if (user?.id) {
      setTried(
        !!localStorage.getItem(DeviceId.googleTimeline + "_tried" + user.id),
      );
    }
  }, [user]);

  const uploadFileHandler = async () => {
    try {
      setLoading(true);

      if (!tried) {
        localStorage.setItem(
          DeviceId.googleTimeline + "_tried" + user.id,
          "true",
        );
      }

      const headers = {
        Authorization: user.token,
        "Content-Type": "application/json",
        "Content-Encoding": "gzip",
        "Error-format": "v2",
        filename: selectedFile.name,
      };

      fetch(config.api + Endpoints.collectionGoogleTimeline, {
        method: "POST",
        headers,
        body: selectedFile,
      })
        .then(() => {
          localStorage.setItem("@GoogleTimeline", "true");
          setLoading(false);

          setResult(Results.success);
        })
        .catch(() => {
          setLoading(false);

          setResult(Results.error);
        });
    } catch (error) {
      setLoading(false);

      setResult(Results.error);
    }
  };

  return (
    <div className={styles.wrapper}>
      <ProgressBar progress={25} />
      <div className={styles.container}>
        <div className={styles.content}>
          <h2>{t("gtl_title")}</h2>
          <h4>{t("gtl_sub_title")}</h4>
        </div>
      </div>

      <div className={styles.firstBg}>
        <div className={styles.content}>
          <StepHeader step={1} title={t("gtl_step_1_title")} />
          <p
            className={styles.text}
            dangerouslySetInnerHTML={{ __html: t("gtl_step_1_text") }}
          />
          <StepImage
            deskImage={images.gtlTutorialS11}
            mobImage={images.gtlTutorialS11m}
          />
        </div>
      </div>

      <div className={styles.secondBg}>
        <div className={styles.content}>
          <h5>{t("gtl_step_1_download")}</h5>

          <p
            className={styles.text}
            dangerouslySetInnerHTML={{ __html: t("gtl_step_1_1_text") }}
          />

          <p
            className={styles.greyText}
            dangerouslySetInnerHTML={{ __html: t("gtl_step_1_2_text") }}
          />

          <StepImage
            deskImage={images.gtlTutorialS12}
            mobImage={images.gtlTutorialS12m}
          />
          <p
            className={styles.greyText}
            dangerouslySetInnerHTML={{ __html: t("gtl_step_1_3_text") }}
          />

          <StepImage deskImage={images.gtlTutorialS13} />
        </div>
      </div>

      <div className={styles.firstBg}>
        <div className={styles.content}>
          <StepHeader step={2} title={t("gtl_step_2_title")} />
          <p
            className={styles.text}
            dangerouslySetInnerHTML={{ __html: t("gtl_step_2_text") }}
          />

          <StepImage
            deskImage={images.gtlTutorialS21}
            mobImage={images.gtlTutorialS21m}
          />
        </div>
      </div>

      <div className={styles.secondBg}>
        <div className={styles.content}>
          <h5>{t("gtl_step_2_export_progress")}</h5>

          <p
            className={styles.text}
            dangerouslySetInnerHTML={{ __html: t("gtl_step_2_1_text") }}
          />
          <p
            className={styles.greyText}
            dangerouslySetInnerHTML={{ __html: t("gtl_step_2_2_text") }}
          />

          <StepImage
            deskImage={images.gtlTutorialS22}
            mobImage={images.gtlTutorialS22m}
          />
        </div>
      </div>

      <div className={styles.firstBg}>
        <div className={styles.content}>
          <StepHeader
            step={3}
            title={t(isOverMd ? "gtl_step_3_title" : "gtl_step_3_title_m")}
          />
          <p
            className={styles.text}
            dangerouslySetInnerHTML={{ __html: t("gtl_step_3_text") }}
          />
          <StepImage
            deskImage={images.gtlTutorialS31}
            mobImage={images.gtlTutorialS31m}
          />
          {isOverMd ? (
            <p
              className={`${styles.text} ${styles.text2}`}
              dangerouslySetInnerHTML={{ __html: t("gtl_step_3_3_text") }}
            />
          ) : null}
          <StepImage deskImage={images.gtlTutorialS32} />
        </div>
      </div>

      <div className={styles.secondBg}>
        <div className={styles.content}>
          <h5>{t("gtl_step_3_log_in")}</h5>
          <p
            className={styles.text}
            dangerouslySetInnerHTML={{ __html: t("gtl_step_3_1_text") }}
          />
          <p
            className={styles.greyText}
            dangerouslySetInnerHTML={{ __html: t("gtl_step_3_2_text") }}
          />
          <StepImage
            deskImage={images.gtlTutorialS33}
            mobImage={images.gtlTutorialS32m}
          />
        </div>
      </div>

      <div className={styles.firstBg}>
        <div className={styles.content}>
          <StepHeader step={4} title={t("gtl_step_4_title")} />
          <p
            className={`${styles.text} ${styles.step4Text}`}
            dangerouslySetInnerHTML={{ __html: t("gtl_step_4_text") }}
          />

          <a
            href={TAKEOUT_GOOGLE_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              type={ButtonTypes.big}
              text={t("button_open_the_link")}
              style={styles.linkButton}
            />
          </a>
          <p
            className={`${styles.text} ${styles.step41Text}`}
            dangerouslySetInnerHTML={{ __html: t("gtl_step_4_1_text") }}
          />
        </div>
      </div>

      <div className={styles.secondBg}>
        <div className={styles.content}>
          <StepHeader step={5} title={t("gtl_step_5_title")} />

          <p
            className={styles.text}
            dangerouslySetInnerHTML={{ __html: t("gtl_step_5_text") }}
          />

          <div className={styles.uploadFileContainer}>
            <div className={styles.uploadFileWrapper}>
              <label
                className={styles.uploadFile}
                onClick={handleClick}
                onDrop={onDrop}
                onDragOver={dragStart}
                onDragEnter={dragEnter}
              >
                <img
                  src={images.uploadFile}
                  className={styles.uploadFileImage}
                  alt="tutorial step"
                />

                <h5
                  dangerouslySetInnerHTML={{
                    __html: t("drag_&_drop_placeholder"),
                  }}
                  className={styles.uploadFileTitle}
                />
                <p className={styles.uploadFileText}>{t("supported_format")}</p>
              </label>

              <input
                type="file"
                ref={hiddenFileInput}
                name="selected_file"
                onChange={handleChange}
                accept=".zip,.rar,.7zip"
                style={{ display: "none" }}
              />

              {selectedFile ? (
                <div className={styles.selectedContainer} onClick={removeFile}>
                  <p className={styles.uploadedTitle}>{t("uploaded_title")}</p>
                  <div
                    className={`${styles.selectedFile}  ${
                      fileDropError ? styles.errorContainer : ""
                    }`}
                  >
                    <p className={styles.fileName}>{selectedFile.name}</p>
                    <img
                      alt={"Uploaded file"}
                      src={
                        fileDropError ? images.crossIcon : images.deleteSelected
                      }
                      className={styles.deleteSelectedIcon}
                    />
                  </div>
                  {fileDropError && (
                    <p className={styles.errorText}>{fileDropError}</p>
                  )}
                </div>
              ) : null}
              {isLoading ? (
                <img
                  src={images.spinner}
                  alt="loading"
                  className={styles.spinner}
                />
              ) : (
                <Button
                  disabled={!selectedFile || !!fileDropError}
                  clickHandler={() => uploadFileHandler()}
                  type={ButtonTypes.small}
                  text={t("button_upload_files")}
                  style={styles.buttonCenterText}
                />
              )}
            </div>
          </div>

          <p
            className={styles.secondaryButton}
            onClick={() => navigate(RoutesPaths.chooseDevices)}
          >
            {t("try_to_connect_another_device")}
          </p>

          <p className={styles.footerContainer}>
            {t("footer_powered_by")}&nbsp;
            <strong className={styles.strong}>Qumata</strong>
          </p>
        </div>
      </div>
    </div>
  );
};

export default TutorialGoogleTimeline;

import Button, { ButtonTypes } from "components/Button/Button";
import { useApp } from "modules/context/AppProvider";
import { images } from "modules/images";
import { FunctionComponent } from "react";
import styles from "./DefaultError.module.scss";
import { useTranslation } from "react-i18next";

export const defaultErrorModalTitle = "error_default_title";

const DefaultErrorModalContent: FunctionComponent = () => {
  const { closeAlert } = useApp();
  const { t } = useTranslation("common");

  return (
    <div className={styles.container}>
      <div className={styles.info}>{t("try_again_in_a_few_minutes")}</div>
      <div className={styles.buttonsContainer}>
        <div className={styles.firstButtonContainer}>
          <Button
            type={ButtonTypes.big}
            text={t("button_got_it")}
            icon={images.rightArrow}
            clickHandler={closeAlert}
          />
        </div>
      </div>
    </div>
  );
};

export default DefaultErrorModalContent;

interface Config {
  api: string;
  hjid: string;
  hjsv: string;
  gaTrackingId: string;
  bugsnagId: string;
  branchKey: string;
  feedbackUrl: string;
}

export const config: Config = {
  api: process.env.REACT_APP_API_URL,
  hjid: process.env.REACT_APP_HJ_ID,
  hjsv: process.env.REACT_APP_HJ_SV,
  gaTrackingId: process.env.REACT_APP_GOOGLE_TRACKING_ID,
  bugsnagId: process.env.REACT_APP_BUGSNAG_ID,
  branchKey: process.env.REACT_APP_BRANCH_KEY,
  feedbackUrl: process.env.REACT_APP_FEEDBACK_URL,
};

export enum DeviceId {
  apple = "apple",
  garmin = "garmin",
  google = "google",
  fitbit = "fitbit",
  underarmour = "underarmour",
  googleTimeline = "googletimeline",
}

export enum AccessStatus {
  connected = "connected",
  notReceived = "notReceived",
  notEnoughData = "notEnoughData",
  loading = "loading",
  collecting = "collecting",
}

export const externalURLs = {
  privacyPolicy: "https://qumata.com/oneclick-privacy-policy",
  termsAndConditions: "https://qumata.com/oneclick-terms-and-conditions",
};

// IN KG
export const MIN_KG = 30;
export const MAX_KG = 301;

// IN STONES
export const MIN_STONE = 4;
export const MAX_STONE = 47;

// const MIN_FRACTION_STONE = 0;
// const MAX_FRACTION_STONE = 14;

// IN POUNDS
export const MIN_POUND = 60;
export const MAX_POUND = 659;

// const MIN_FRACTION_POUNDS = 0;
// const MAX_FRACTION_POUNDS = 0.9;

export const MIN_CM = 60;
export const MAX_CM = 303;

// const MIN_MM = 0.0;
// const MAX_MM = 0.9;

export const MIN_FEET = 2;
export const MAX_FEET = 10;

// const MIN_INCH = 0;
// const MAX_INCH = 12;

export const TAKEOUT_GOOGLE_URL = "https://takeout.google.com";

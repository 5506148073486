import React from "react";
import { AccessStatus, DeviceId } from "modules/constants";
import { useNavigate } from "react-router-dom";
import { RoutesPaths } from "modules/routes";
import DeviceHeader from "../DeviceHeader/DeviceHeader";
import { mapIdToDevice } from "screens/ConnectDevice/ConnectDevice";
import styles from "./ConnectedDevice.module.scss";
import Button, { ButtonTypes } from "components/Button/Button";
import { images } from "modules/images";
import Footer from "components/Footer/Footer";
import { useTranslation } from "react-i18next";

const ConnectedDevice = ({ device }: { device: DeviceId }) => {
  const navigate = useNavigate();
  const { t } = useTranslation("common");

  return (
    <div className={styles.wrapper}>
      <div className={styles.centerContainer}>
        <div className={styles.headerContainer}>
          <DeviceHeader device={device} status={AccessStatus.connected} />
        </div>
        <div className={styles.infoContainer}>
          {`${t("connected_device_your")} ${mapIdToDevice[device].title} ${t(
            "connected_device_title",
          )}`}
        </div>

        <div className={styles.bottomContainer}>
          <div className={styles.buttonContainer}>
            <Button
              type={ButtonTypes.big}
              text={t("button_next")}
              icon={images.rightArrow}
              clickHandler={() => navigate(RoutesPaths.chooseDevices)}
            />
          </div>

          <div className={styles.footerContainer}>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConnectedDevice;

import React, { ReactElement, useEffect, useState } from "react";
import ContactUs from "components/Modals/ContactUs/ContactUs";
import { handlePreventDefault } from "modules/utils";
import styles from "./AlertProvider.module.scss";
import { ReactComponent as CloseButton } from "assets/images/icons/close.svg";
import { useTranslation } from "react-i18next";

const LANGUAGE_STORE_KEY = "@LANGUAGE_STORE_KEY";
interface Props {
  onClose?: () => void;
  children: ReactElement;
}

type AlertContextType = {
  visible: boolean;
  showAlert: (title: string, content: React.ReactElement) => void;
  closeAlert: () => void;
  showContactUs: () => void;
  currentLanguage: string;
  changeLanguage: (lng: string) => void;

  languagePopup: boolean;
  showLanguagePopup: (isShow: boolean) => void;
};

export type AlertDataType = {
  title: string;
  content: React.ReactElement;
} | null;

export const AlertContext = React.createContext<AlertContextType>(null);

const AppProvider: React.FC<Props> = ({ children }) => {
  const [formVisible, setFormVisible] = React.useState(false);
  const [visible, setShowAlert] = React.useState(false);
  const [languagePopup, showLanguagePopup] = useState(false);
  const { t } = useTranslation("common");
  const { i18n } = useTranslation();

  const [data, setData] = React.useState<AlertDataType>();
  const [currentLanguage, setLanguage] = useState("en");

  const showContactUs = () => {
    closeAlert();
    setFormVisible(true);
  };

  const changeLanguage = (lng: string) => {
    setLanguage(lng);
    i18n.changeLanguage(lng);
    localStorage.setItem(LANGUAGE_STORE_KEY, lng);
    showLanguagePopup(false);
  };

  const showAlert = (title: string, content: React.ReactElement): void => {
    setData({ title, content });
    setShowAlert(true);
  };

  const closeAlert = () => {
    setShowAlert(false);
    setData(null);
  };

  useEffect(() => {
    document.body.style.overflow = visible || formVisible ? "hidden" : "unset";
  }, [formVisible, visible]);

  useEffect(() => {
    const language = localStorage.getItem(LANGUAGE_STORE_KEY);
    if (language) {
      setLanguage(language);
      i18n.changeLanguage(language);
    }
  }, [i18n]);

  return (
    <AlertContext.Provider
      value={{
        visible,
        currentLanguage,
        languagePopup,
        showLanguagePopup,
        changeLanguage,
        showAlert,
        showContactUs,
        closeAlert,
      }}
    >
      {visible && (
        <div
          className={styles.wrapper}
          onClick={closeAlert}
          style={{ height: "100%" }}
        >
          <div className={styles.container} onClick={handlePreventDefault}>
            <div className={styles.header}>
              <div className={styles.bigTitle}>{t(data.title)}</div>
              <div className={styles.closeButtonContainer}>
                <button className={styles.closeButton} onClick={closeAlert}>
                  <CloseButton />
                </button>
              </div>
            </div>

            <div className={styles.contentContainer}>
              <div className={styles.smallTitle}>{data.title}</div>
              <div className={styles.content}>{data.content}</div>
            </div>
          </div>
        </div>
      )}

      {children}

      {formVisible && <ContactUs onClose={() => setFormVisible(false)} />}
    </AlertContext.Provider>
  );
};

export const useApp = () => React.useContext(AlertContext);

export default AppProvider;

import React, { FunctionComponent, useState } from "react";
import { useUser } from "modules/context/UserProvider";
import { useApp } from "modules/context/AppProvider";
import { useNavigate } from "react-router-dom";
import { useProfileQuery } from "modules/utils/hooks";
import ContactUs from "components/Modals/ContactUs/ContactUs";
import apiCall, { Endpoints } from "modules/api";
import { RoutesPaths } from "modules/routes";
import { images } from "modules/images";
import styles from "./Home.module.scss";
import Footer from "components/Footer/Footer";
import StartButton from "components/StartButton/StartButton";
import UnableToConnectModalContent, {
  unableToConnectModalTitle,
} from "components/Modals/UnableToConnect/UnableToConnect";
import DefaultErrorModalContent, {
  defaultErrorModalTitle,
} from "components/Modals/DefaultError/DefaultError";
import UserNotFoundModalContent, {
  userNotFountModalTitle,
} from "components/Modals/UserNotFound/UserNotFound";
import { useTranslation } from "react-i18next";

enum ErrorMessage {
  networkError = "Network Error",
  userNotFound = "User not found",
}

const HomePage: FunctionComponent = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("common");
  const { data: profile, isLoading: isProfileLoading } = useProfileQuery();
  const { user, pending: userPending } = useUser();
  const { showAlert } = useApp();

  const [showContactUs, toggleContactUs] = useState(false);
  const [pending, setPending] = useState(false);

  const handleStart = async () => {
    try {
      setPending(true);
      if (!(user && user.id)) {
        throw Error(ErrorMessage.userNotFound);
      }

      // check if user available
      await apiCall.get(Endpoints.profile, {
        headers: {
          Authorization: user?.token,
          "Content-Type": "application/json",
        },
      });

      // Check if user finished flow navigate user to thank you screen or well done screen
      let nextRoute = RoutesPaths.signIn;

      const isFinished =
        typeof profile.thank_you_screen_visited_at === "number";

      const isWellDone =
        typeof profile.waiting_data_screen_visited_at === "number";

      if (isFinished) {
        nextRoute = RoutesPaths.thankYou;
      } else if (isWellDone) {
        nextRoute = RoutesPaths.wellDone;
      }

      navigate(nextRoute);
    } catch (error) {
      let title = defaultErrorModalTitle;
      let description = <DefaultErrorModalContent />;

      if (error.message && error.message === ErrorMessage.networkError) {
        title = unableToConnectModalTitle;
        description = <UnableToConnectModalContent />;
      }

      if (error.message && error.message === ErrorMessage.userNotFound) {
        title = userNotFountModalTitle;
        description = <UserNotFoundModalContent />;
      }

      if (error.response && error.response.status === 404) {
        title = userNotFountModalTitle;
        description = <UserNotFoundModalContent />;
      }

      if (error.response && error.response.status === 403) {
        title = unableToConnectModalTitle;
        description = <UnableToConnectModalContent />;
      }

      return showAlert(t(title), description);
    } finally {
      setPending(false);
    }
  };

  const isLoading = pending || isProfileLoading || userPending;

  return (
    <div className={styles.wrapper}>
      <div className={styles.imageContainer}>
        <img src={images.homeImage} alt="OneClick" />
      </div>

      <div className={styles.contentContainer}>
        <h1 className={styles.title}>
          {user?.redirect ? t("home_title_1") : t("home_title_1")}
        </h1>
        <div className={styles.mobileImageContainer}>
          <img src={images.homeImage} alt="OneClick" />
        </div>
        <section className={styles.content}>
          {user?.redirect ? (
            <>
              {t("home_sub_title_1_1")}
              <br />
              {t("home_sub_title_1_2")}
            </>
          ) : (
            t("home_sub_title_2")
          )}
        </section>

        <div className={styles.buttonWrapper}>
          <div className={styles.buttonContainer}>
            <StartButton
              disabled={isLoading}
              text={t("button_start")}
              clickHandler={handleStart}
            />
          </div>
        </div>
      </div>

      <Footer />

      {showContactUs && <ContactUs onClose={() => toggleContactUs(false)} />}
    </div>
  );
};

export default HomePage;

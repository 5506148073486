import React, { useState } from "react";

import styles from "./style.module.scss";
import TutorialGoogleTimeline from "./TutorialGoogleTimeline";
import ResultGoogleTimeline from "./ResultGoogleTimeline";

export enum Results {
  success = "success",
  error = "Error",
}
const ConnectGoogleTimelineTutorial = () => {
  const [result, setResult] = useState<Results | null>();

  return (
    <div className={styles.wrapper}>
      {result ? (
        <ResultGoogleTimeline status={result} reset={() => setResult(null)} />
      ) : (
        <TutorialGoogleTimeline setResult={setResult} status={result} />
      )}
    </div>
  );
};

export default ConnectGoogleTimelineTutorial;

import React from "react";
import styles from "./ContactUsButton.module.scss";
import { useAppTheme } from "modules/context/ThemeProvider";
import { useApp } from "modules/context/AppProvider";
import { ReactComponent as ContactButtonWhite } from "assets/images/icons/contact-us-white.svg";
import { ReactComponent as ContactButtonPurple } from "assets/images/icons/contact-us-purple.svg";
import { useTranslation } from "react-i18next";

const ContactUsButton: React.FunctionComponent = () => {
  const { colors } = useAppTheme();
  const { showContactUs } = useApp();
  const { t } = useTranslation("common");

  return (
    <div
      onClick={showContactUs}
      className={styles.wrapper}
      id="contact-us-button"
    >
      <div className={styles.smallWrapper}>
        <ContactButtonPurple className={styles.smallIcon} />
      </div>
      <div
        className={styles.bigWrapper}
        style={{ backgroundColor: colors.background }}
      >
        <span>{t("button_contact_us")}</span>
        <ContactButtonWhite className={styles.bigIcon} />
      </div>
    </div>
  );
};

export default ContactUsButton;

import qs from "qs";

const changeRedirectURL = (originURL, hasEnoughData) => {
  const url = new URL(originURL);
  const { origin, search, pathname } = url;
  const newSearch = search.replace("?", "");
  const searchParams = qs.parse(newSearch);
  searchParams.QumataStatus = hasEnoughData.toString();
  const newSearchParams = "/?" + qs.stringify(searchParams);
  const newURL = origin + pathname + newSearchParams;
  return newURL;
};

export default changeRedirectURL;

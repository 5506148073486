import { FunctionComponent } from "react";
import styles from "./ToggleButton.module.scss";

interface ToggleButtonInterface {
  toggled: boolean;
  clickHandler: () => void;
}

const ToggleButton: FunctionComponent<ToggleButtonInterface> = ({
  toggled,
  clickHandler,
}) => (
  <div
    className={`${styles.toggleButton} ${toggled === true ? styles.moved : ""}`}
    onClick={clickHandler}
  >
    <div
      className={`${styles.toggleCircle} ${
        toggled === true ? styles.moved : ""
      }`}
    ></div>
  </div>
);

export default ToggleButton;

import React, { ReactElement, useEffect } from "react";
import { useRefetchProfileQuery } from "modules/utils/hooks";
import changeRedirectURL from "modules/utils/redirect";
import { useUser } from "./UserProvider";

export const RedirectContext = React.createContext(null);

const RedirectProvider: React.FC<{ children: ReactElement }> = ({
  children,
}) => {
  const { user } = useUser();
  const { data: profile } = useRefetchProfileQuery();

  useEffect(() => {
    if (!profile) return;

    const isFinished =
      profile.thank_you_screen_visited_at &&
      typeof profile.thank_you_screen_visited_at === "number";

    const isWellDone =
      profile.waiting_data_screen_visited_at &&
      typeof profile.waiting_data_screen_visited_at === "number";

    const finishedJourney = isFinished || isWellDone;

    if (user.redirect && finishedJourney) {
      const changedRedirectURL = changeRedirectURL(user.redirect, isFinished);
      window.location.replace(changedRedirectURL);
    }
  }, [user, profile]);

  return (
    <RedirectContext.Provider value={{}}>{children}</RedirectContext.Provider>
  );
};

export default RedirectProvider;

export const images = {
  appleHealthLogo: require("../assets/images/apple-health-logo.png"),
  appleHealthTutorial1: require("../assets/images/apple-health-tutorial-1.png"),
  appleHealthTutorial2: require("../assets/images/apple-health-tutorial-2.png"),
  appleHealthTutorial3: require("../assets/images/apple-health-tutorial-3.png"),
  appleHealthTutorial4: require("../assets/images/apple-health-tutorial-4.png"),
  appleHealthTutorial2_13: require("../assets/images/apple-health-tutorial-13-2.png"),
  appleHealthTutorial3_13: require("../assets/images/apple-health-tutorial-13-3.png"),
  arrowLeft: require("../assets/images/arrow-left.png"),
  arrowRight: require("../assets/images/arrow-right.png"),
  send: "/images/send.svg",
  rightArrow: "/images/rightArrow.svg",
  leftArrow: require("../assets/images/leftArrow.png"),
  contactUs: require("../assets/images/contact-us.png"),
  contactUsWhite: require("../assets/images/contact-us-white.png"),
  contactUsPurple: require("../assets/images/contact-us-purple.png"),
  home: require("../assets/images/home.png"),
  homeImage: require("../assets/images/homeImage.png"),
  warning: "/images/warning.svg",
  googleTimelineUser: "/images/google_timeline_user.svg",
  googleTimelinePassword: "/images/google_timeline_password.svg",

  fitbitIcon: require("../assets/images/fitbit.png"),
  fitbitLogo: require("../assets/images/fitbit-logo.png"),
  garminIcon: require("../assets/images/garmin.png"),
  garminLogo: require("../assets/images/garmin-logo.png"),
  googleTimelineLogo: require("../assets/images/google-timeline.png"),
  googleFitIcon: require("../assets/images/google-fit.png"),
  googleFitLogo: require("../assets/images/google-fit-logo.png"),
  underarmourLogo: require("../assets/images/underarmour-logo.png"),
  healthyHealthIllustration: require("../assets/images/healthy-health-illustration.png"),
  helpWhite: require("../assets/images/help-white.png"),
  lock: require("../assets/images/lock.png"),
  pending: require("../assets/images/pending.gif"),
  pendingDots: require("../assets/images/pending.png"),
  question: require("../assets/images/question.png"),
  sent: require("../assets/images/sent.png"),
  spinner: require("../assets/images/spinner.gif"),
  oneclick: require("../assets/images/oneclick.png"),
  rotate: require("../assets/images/rotate.png"),
  loader: require("../assets/images/loader.png"),
  close: require("../assets/images/close.png"),
  add: require("../assets/images/add.png"),
  empty: require("../assets/images/empty.png"),
  bin: require("../assets/images/bin.png"),
  backArrow: require("../assets/images/backArrow.png"),
  gtlTutorialS11m: require("../assets/images/gtl_tutorial_s_1_1_m.png"),
  gtlTutorialS12m: require("../assets/images/gtl_tutorial_s_1_2_m.png"),
  gtlTutorialS21m: require("../assets/images/gtl_tutorial_s_2_1_m.png"),
  gtlTutorialS22m: require("../assets/images/gtl_tutorial_s_2_2_m.png"),
  gtlTutorialS31m: require("../assets/images/gtl_tutorial_s_3_1_m.png"),
  gtlTutorialS32m: require("../assets/images/gtl_tutorial_s_3_2_m.png"),
  gtlTutorialS11: require("../assets/images/gtl_tutorial_s_1_1.png"),
  gtlTutorialS12: require("../assets/images/gtl_tutorial_s_1_2.png"),
  gtlTutorialS13: require("../assets/images/gtl_tutorial_s_1_3.png"),
  gtlTutorialS21: require("../assets/images/gtl_tutorial_s_2_1.png"),
  gtlTutorialS22: require("../assets/images/gtl_tutorial_s_2_2.png"),
  gtlTutorialS31: require("../assets/images/gtl_tutorial_s_3_1.png"),
  gtlTutorialS32: require("../assets/images/gtl_tutorial_s_3_2.png"),
  gtlTutorialS33: require("../assets/images/gtl_tutorial_s_3_3.png"),
  uploadFile: require("../assets/images/upload_icon.png"),
  deleteSelected: require("../assets/images/Delete.png"),
  crossIcon: require("../assets/images/Cross icon.png"),
  cloudChecked: require("../assets/images/cloud_checked.png"),

  appleTutorial: {
    old: [
      require("../assets/images/apple-tutorial/old/1.png"),
      require("../assets/images/apple-tutorial/old/2.png"),
      require("../assets/images/apple-tutorial/old/3.png"),
      require("../assets/images/apple-tutorial/old/4.png"),
      require("../assets/images/apple-tutorial/old/5.png"),
    ],
    new: [
      require("../assets/images/apple-tutorial/new/1.png"),
      require("../assets/images/apple-tutorial/new/2.png"),
      require("../assets/images/apple-tutorial/new/3.png"),
      require("../assets/images/apple-tutorial/new/4.png"),
      require("../assets/images/apple-tutorial/new/5.png"),
      require("../assets/images/apple-tutorial/new/6.png"),
    ],
  },
};

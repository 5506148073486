import Button, { ButtonTypes } from "components/Button/Button";
import Footer from "components/Footer/Footer";
import ToggleButton from "components/ToggleButton/ToggleButton";
import { images } from "modules/images";
import { FunctionComponent, ReactElement, useState } from "react";
import styles from "./AppleHealthTutorialDesktop.module.scss";
import { useTranslation } from "react-i18next";

interface TutorialsInterface {
  old: Array<ReactElement>;
  new: Array<ReactElement>;
}

enum Tutorials {
  new,
  old,
}

interface AppleHealthTutorialDesktopInterface {
  onClose: () => void;
}

const AppleHealthTutorialDesktop: FunctionComponent<
  AppleHealthTutorialDesktopInterface
> = ({ onClose }) => {
  const [tutorial, setTutorial] = useState(Tutorials.new);
  const [toggleMoved, setToggleMoved] = useState(false);
  const isNewTutorial = tutorial === Tutorials.new;
  const { t } = useTranslation("common");

  const imagesRoute = isNewTutorial
    ? images.appleTutorial.new
    : images.appleTutorial.old;

  const tutorials: TutorialsInterface = {
    old: [
      <>
        {t("ah_tutorial_open")}{" "}
        <span className={styles.bold}>
          {t("ah_tutorial_the")}
          <br />
          {t("ah_tutorial_ah_app")}
        </span>
        <br />
        {t("ah_tutorial_on_your_mob_pone")}
      </>,
      <>
        {t("ah_tutorial_select")}{" "}
        <span className={styles.bold}>{t("ah_tutorial_sources")}</span>{" "}
        {t("ah_tutorial_in_the")}
        <br />
        {t("ah_tutorial_menu_bar")}
      </>,

      <>
        {t("ah_tutorial_select_the")}
        <br />
        <span className={styles.bold}>"OneClick-Connect"</span> app
      </>,
      <>
        {t("ah_tutorial_select")}{" "}
        <span className={styles.bold}>
          {t("ah_tutorial_turn_all")} <br />
          {t("ah_tutorial_categories_on")}
        </span>{" "}
        {t("ah_tutorial_to_give")}
        <br />
        {t("ah_tutorial_your_consent")}
        <div className={styles.textDividerWrapper}>
          <div className={styles.textDivider}></div>
        </div>
        {t("ah_tutorial_if_you_want_to")}
        <br />
        <span className={`${styles.bold} ${styles.red}`}>
          {t("ah_tutorial_revoke_consent")}
        </span>{" "}
        {t("ah_tutorial_select")}
        <br />
        <span className={styles.bold}>
          {t("ah_tutorial_turn_all_categories_off")}
        </span>
      </>,
      <>
        {t("ah_tutorial_return_to")}
        <br />
        <span className={styles.bold}>“OneClick-Connect”</span>
        <br />
        {t("ah_tutorial_app_to_continue")}
      </>,
    ],
    new: [
      <>
        {t("ah_tutorial_open")}{" "}
        <span className={styles.bold}>
          {t("ah_tutorial_the")}
          <br />
          {t("ah_tutorial_ah_app")}
        </span>
        <br />
        {t("ah_tutorial_on_your_mobile_phone")}
      </>,

      <>
        {t("ah_tutorial_select")}{" "}
        <span className={styles.bold}>{t("ah_tutorial_profile")}</span>{" "}
        {t("ah_tutorial_on")}
        <br />
        {t("ah_tutorial_the_summary_page")}
      </>,
      <>
        {t("ah_tutorial_choose")}{" "}
        <span className={styles.bold}>{t("ah_tutorial_apps")}</span>{" "}
        {t("ah_tutorial_from")}
        <br />
        {t("ah_tutorial_the_list")}
      </>,
      <>
        {t("ah_tutorial_select_the")}
        <br />
        <span className={styles.bold}>“OneClick-Connect”</span>{" "}
        {t("ah_tutorial_app")}
      </>,
      <>
        {t("ah_tutorial_select")}{" "}
        <span className={styles.bold}>
          {t("ah_tutorial_turn_all")}
          <br />
          {t("ah_tutorial_categories_on")}
        </span>{" "}
        {t("ah_tutorial_on")}
        to give <br />
        {t("ah_tutorial_your_consent")}
        <div className={styles.textDividerWrapper}>
          <div className={styles.textDivider}></div>
        </div>
        {t("ah_tutorial_if_you_want_to")}
        <br />
        <span className={`${styles.bold} ${styles.red}`}>
          {t("ah_tutorial_to_disconnect")}
        </span>{" "}
        {t("ah_tutorial_select")}
        <br />
        <span className={styles.bold}>
          {t("ah_tutorial_turn_all_categories_off")}
        </span>
      </>,
      <>
        {t("ah_tutorial_return_to")}
        <br />
        <span className={styles.bold}>“OneClick-Connect”</span>
        <br />
        {t("ah_tutorial_app_to_continue")}
      </>,
    ],
  };

  const changeTutorial = () => {
    setTutorial(tutorial === Tutorials.new ? Tutorials.old : Tutorials.new);

    setToggleMoved(!toggleMoved);
  };

  const tutorialsArray = isNewTutorial ? tutorials.new : tutorials.old;

  return (
    <div className={styles.desktopContainer}>
      <div className={styles.backButtonContainer}>
        <div className={styles.backButton} onClick={onClose}>
          <img
            src={images.backArrow}
            alt="Back"
            className={styles.backButtonArrow}
          />
          <div className={styles.backButtonText}>{t("button_back")}</div>
        </div>
      </div>
      <div className={styles.desktopTutorialContainer}>
        <div className={styles.desktopTitle}>{t("ah_tutorial_title")}</div>
        <div className={styles.desktopTutorialHint}>
          {t("ah_tutorial_sub_title")}
        </div>
        <div className={styles.desktopToggleContainer}>
          <div className={styles.desktopToggleInfo}>
            {t("ah_tutorial_toggle")}{" "}
            <span className={styles.desktopToggleInfoBold}>
              {t("ah_tutorial_screenshots_do_not_match")}
            </span>{" "}
            {t("ah_tutorial_your_device")}
          </div>
          <ToggleButton toggled={toggleMoved} clickHandler={changeTutorial} />
        </div>

        <div className={styles.desktopTutorialElementsWrappeer}>
          <div className={styles.desktopTutorialElementsContainer}>
            {tutorialsArray.map((element, index, arr) => (
              <div
                className={`${styles.desktopElementContainer} ${
                  isNewTutorial ? styles.newTutorialElement : ""
                } ${index === 0 ? styles.firstElement : ""} ${
                  index + 1 === arr.length ? styles.lastElement : ""
                }`}
                key={index}
              >
                <div
                  className={`${styles.desktopElementImageContainer} ${
                    isNewTutorial ? styles.newTutorialImage : ""
                  }`}
                >
                  <img src={imagesRoute[index]} alt="Tutorial" />
                  {isNewTutorial && (
                    <div className={styles.desktopSeparatorWrapper}>
                      <div className={styles.desktopSeparator}></div>
                    </div>
                  )}
                </div>
                <div className={styles.desktopTutorialStep}>
                  Step {(index + 1).toString()}/{imagesRoute.length.toString()}:
                </div>

                <div className={styles.tutorialText}>
                  {tutorialsArray[index]}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className={styles.bottomContainer}>
          <div className={styles.buttonContainer}>
            <Button
              type={ButtonTypes.big}
              text="Done"
              clickHandler={onClose}
              icon={images.rightArrow}
            />
          </div>

          <div className={styles.footerContainer}>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppleHealthTutorialDesktop;

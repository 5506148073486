import React, { useState, useEffect } from "react";
import apiCall, { Endpoints } from "modules/api";
import Bugsnag from "@bugsnag/js";
import { useUser } from "modules/context/UserProvider";
import { DeviceId } from "modules/constants";
import NotConnectedDevice from "components/NotConnectedDevice/NotConnectedDevice";
import StartConnectDevice from "components/StartConnectDevice/StartConnectDevice";
import CollectingDevice from "components/CollectingDevice/CollectingDevice";
import ConnectedDevice from "components/ConnectedDevice/ConnectedDevice";
import { useApp } from "modules/context/AppProvider";
import { useCollectionStatusQuery } from "modules/utils/hooks";
import Layout from "components/Layout/Layout";
import { RoutesPaths } from "modules/routes";
import Pending from "components/Pending";
import { images } from "modules/images";
import StartConnectGoogle from "components/StartConnectGoogle/StartConnectGoogle";
import CanNotUploadDataModalContent, {
  canNotUploadDataModalTitle,
} from "components/Modals/CanNotUploadData/CanNotUploadData";
import { useTranslation } from "react-i18next";

type ConnectDeviceProps = {
  deviceId: DeviceId;
};

export const mapIdToDevice = {
  [DeviceId.apple]: {
    title: "Apple Health",
    logo: images.appleHealthLogo,
    redirectRoute: "",
    connectionTitle: "a_h_connection",
  },
  [DeviceId.google]: {
    title: "Google Fit",
    logo: images.googleFitLogo,
    redirectRoute: RoutesPaths.uploadingGoogleFit,
    connectionTitle: "g_f_connection",
  },
  [DeviceId.garmin]: {
    title: "Garmin",
    logo: images.garminLogo,
    redirectRoute: RoutesPaths.uploadingGarmin,
    connectionTitle: "garmin_connection",
  },
  [DeviceId.fitbit]: {
    title: "Fitbit",
    logo: images.fitbitLogo,
    redirectRoute: RoutesPaths.uploadingFitbit,
    connectionTitle: "fitbit_connection",
  },

  [DeviceId.underarmour]: {
    title: "Map My Run",
    logo: images.underarmourLogo,
    redirectRoute: RoutesPaths.uploadingUnderarmour,
    connectionTitle: "ua_connection",
  },

  [DeviceId.googleTimeline]: {
    title: "Location History Connection Process ",
    logo: images.googleTimelineLogo,
    redirectRoute: RoutesPaths.uploadingUnderarmour,
    connectionTitle: "googleTimeline_connection",
  },
};

const ConnectDeviceScreen: React.FunctionComponent<ConnectDeviceProps> = ({
  deviceId,
}) => {
  const { user } = useUser();
  const { showAlert, closeAlert } = useApp();
  const { data, isLoading: sourcePending } = useCollectionStatusQuery();
  const { t } = useTranslation("common");

  const provider = data?.find((i) => i.name === deviceId);

  const [pending, setPending] = useState(false);

  const [tried, setTried] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    if (user?.id)
      setTried(!!localStorage.getItem(deviceId + "_tried" + user.id));
  }, [user, deviceId]);

  const handleConnect = async () => {
    if (!user?.token) return;
    setPending(true);

    try {
      if (!tried) {
        localStorage.setItem(deviceId + "_tried" + user.id, "true");
      }

      const headers = {
        Authorization: user?.token,
        "Content-Type": "application/json",
      };

      const { origin } = window.location;
      const redirectUrl = `${origin}${mapIdToDevice[deviceId].redirectRoute}`;

      const {
        data: { url },
      } = await apiCall.post(
        Endpoints.authProvider + deviceId,
        { redirectUrl },
        { headers },
      );

      window.open(url, "_self");
    } catch (error) {
      setPending(false);
      Bugsnag.notify(error);

      showAlert(
        t(canNotUploadDataModalTitle),
        <CanNotUploadDataModalContent clickHandler={() => closeAlert()} />,
      );
    }
  };
  const renderBody = () => {
    switch (true) {
      case pending || sourcePending:
        return <Pending />;
      case !provider:
        return null;
      case provider.is_receiving_data && provider.has_enough_data:
        return <ConnectedDevice device={deviceId} />;
      case provider.is_receiving_data && !provider.has_enough_data:
        return <CollectingDevice device={deviceId} />;
      case tried && !provider.is_receiving_data:
        return (
          <NotConnectedDevice device={deviceId} handleConnect={handleConnect} />
        );
      default:
        return (
          <>
            {deviceId === "google" ? (
              <StartConnectGoogle
                device={deviceId}
                onConnect={handleConnect}
                isLoading={pending || sourcePending}
              />
            ) : (
              <StartConnectDevice
                device={deviceId}
                onConnect={handleConnect}
                isLoading={pending || sourcePending}
              />
            )}
          </>
        );
    }
  };

  return <Layout progressBarPercent={40}>{renderBody()}</Layout>;
};

export default ConnectDeviceScreen;

import React from "react";
import { useNavigate } from "react-router";
import { AccessStatus, DeviceId } from "modules/constants";
import DeviceHeader from "components/DeviceHeader/DeviceHeader";
import styles from "./CollectingDevice.module.scss";
import { mapIdToDevice } from "screens/ConnectDevice/ConnectDevice";
import { RoutesPaths } from "modules/routes";
import Button, { ButtonTypes } from "components/Button/Button";
import { images } from "modules/images";
import Footer from "components/Footer/Footer";
import SecondaryButton from "components/SecondaryButton/SecondaryButton";
import { useTranslation } from "react-i18next";

const CollectingDevice = ({ device }: { device: DeviceId }) => {
  const navigate = useNavigate();
  const { t } = useTranslation("common");

  return (
    <div className={styles.wrapper}>
      <div className={styles.centerContainer}>
        <div className={styles.headerContainer}>
          <DeviceHeader device={device} status={AccessStatus.collecting} />
        </div>
        <div className={styles.infoContainer}>
          {`${t("collecting_device_ino_text_1_1")} ${
            mapIdToDevice[device].title
          } 
          ${t("collecting_device_ino_text_1_2")}`}
          <br />
          <br />
          {t("collecting_device_ino_text_1_3")}
          <br />
          <br />
          {t("collecting_device_ino_text_1_4")}
        </div>

        <div className={styles.bottomContainer}>
          <div className={styles.buttonsContainer}>
            <div className={styles.firstButtonContainer}>
              <Button
                type={ButtonTypes.big}
                text={t("button_next")}
                icon={images.rightArrow}
                clickHandler={() => navigate(RoutesPaths.profile)}
              />
            </div>
            <SecondaryButton
              text={t("try_another_device")}
              clickHandler={() => navigate(RoutesPaths.chooseDevices)}
            />
          </div>

          <div className={styles.footerContainer}>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CollectingDevice;

import qs from "qs";
import branch from "branch-sdk";
import Bugsnag from "@bugsnag/js";
import { User } from "typing";
import apiCall, { Endpoints } from "modules/api";
import { config } from "modules/constants";

const {
  localStorage,
  location: { search },
} = window;
export const USER = "oc_user";

/** retrieves user from localStorage
 *
 * @returns user object {id: string, token: string}
 * or null if user not found
 */
export const getInitialUser = (): User | null => {
  try {
    const userString = window.localStorage.getItem(USER);

    return JSON.parse(userString);
  } catch (error) {
    return null;
  }
};

/** retrieves user from query params
 *
 * @returns user object {id: string, token: string}
 * or null if user not found
 */
export const getUserFromQuery = async (): Promise<User | null> => {
  if (!search) return;

  const query = search.replace("?", "");
  let params = qs.parse(query);

  if (
    params.ExternalBroker &&
    params.ExternalBrokerID &&
    params.ExternalID &&
    params.ProviderToken
  ) {
    try {
      const createdUser = await createExternalUser(
        params.ExternalID,
        params.ExternalBroker,
        params.ExternalBrokerID,
        params.ProviderToken,
      );

      const { id, token, redirect } = createdUser;
      localStorage.setItem(USER, JSON.stringify({ id, token, redirect }));
      return { id: id, token: token, redirect: redirect };
    } catch (error) {
      Bugsnag.notify(error);
      return null;
    }
  } else {
    try {
      const queryString = atob(query);
      const { id, token } = qs.parse(queryString);

      if (id && token) {
        localStorage.setItem(USER, JSON.stringify({ id, token }));
        return { id: id.toString(), token: token.toString() };
      }
    } catch (error) {
      Bugsnag.notify(error);
      return null;
    }
  }
};

const createExternalUser = async (
  ExternalID: string | qs.ParsedQs | string[] | qs.ParsedQs[],
  ExternalBroker: string | qs.ParsedQs | string[] | qs.ParsedQs[],
  ExternalBrokerID: string | qs.ParsedQs | string[] | qs.ParsedQs[],
  ProviderToken: string | qs.ParsedQs | string[] | qs.ParsedQs[],
): Promise<User | null> => {
  const url =
    config.api +
    Endpoints.createExternalUser +
    "?" +
    `ExternalID=${ExternalID}&ExternalBroker=${ExternalBroker}&ExternalBrokerID=${ExternalBrokerID}&ProviderToken=${ProviderToken}`;

  const response = await apiCall.post(url);
  const dataEncoded: string = response.data;
  const dataDecoded = atob(dataEncoded);
  const { id, token, redirect } = qs.parse(dataDecoded);
  return {
    id: id.toString(),
    token: token.toString(),
    redirect: redirect.toString(),
  };
};

type ResultData = {
  data_parsed: {
    ["username"]?: string;
    ["token"]?: string;
    ["server"]?: string;
  };
};

/** Make promise with callback func
 *
 * @returns params if app open from deep link
 *
 */
const getBranchData = (): Promise<ResultData> =>
  new Promise((resolve, reject) => {
    const cb = (err: string, results: ResultData) =>
      err ? reject(err) : resolve(results);

    return branch.data(cb);
  });

/** Retrieves user from deep link params with branch io sdk
 *
 * @returns user object {id: string, token: string}
 * or null if user not found
 */
export const getUserFromLink = async (): Promise<User | null> => {
  try {
    const { data_parsed } = await getBranchData();
    const username = data_parsed?.["username"];
    const token = data_parsed?.["token"];
    const server = data_parsed?.["server"]
      ? `https://${data_parsed?.["server"]}`
      : "";

    if (!username || !token) {
      return null;
    }

    localStorage.setItem(USER, JSON.stringify({ id: username, token, server }));

    return { id: username, token };
  } catch (error) {
    return null;
  }
};

export const destroyUser = () => {
  localStorage.removeItem(USER);
};

import React, { useState } from "react";
import styles from "./TextInput.module.scss";

interface TextInputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  disabled?: boolean;
  error?: boolean;
  errorText?: string | any;
  label?: string;
  multiline?: boolean;
  onChange?: (event: any) => void;
  placeholder?: string;
  value?: string;
  required?: boolean;
}

const TextInput: React.FunctionComponent<TextInputProps> = ({
  error,
  label,
  value,
  onChange,
  disabled,
  multiline,
  errorText,
  placeholder,
  required,
  ...props
}) => {
  const inputRef = React.createRef<HTMLInputElement>();
  const maxLength = 200;
  const [inputLength, setInputLength] = useState<number>(0);

  return (
    <div className={styles.wrapper}>
      <div className={styles.labelContainer}>
        <span className={styles.label}>
          {label}
          {required && <span className={styles.requiredMark}> *</span>}
        </span>
        {multiline && value.length > 0 && (
          <div
            className={`${styles.counter} ${error ? styles.errorCounter : ""}`}
          >
            {inputLength} / {maxLength.toString()}
          </div>
        )}
      </div>
      {multiline && (
        <textarea
          className={`${styles.textInput} ${styles.multiline} ${
            error ? styles.error : ""
          }`}
          disabled={disabled}
          maxLength={maxLength}
          onChange={(e) => {
            onChange(e);
            setInputLength(e.target.value.length);
          }}
          placeholder={placeholder}
          spellCheck={false}
          value={value}
        />
      )}
      {!multiline && (
        <input
          ref={inputRef}
          className={`${styles.textInput} ${error ? styles.error : ""}`}
          disabled={disabled}
          onChange={onChange}
          placeholder={placeholder}
          spellCheck={false}
          type="text"
          value={value}
          {...props}
        />
      )}
      {errorText && (
        <div className={styles.errorContainer}>
          <span className={styles.errorText}>{errorText}</span>
        </div>
      )}
    </div>
  );
};

export default TextInput;

import { FunctionComponent } from "react";
import Button, { ButtonTypes } from "components/Button/Button";
import { images } from "modules/images";
import styles from "./CanNotUploadData.module.scss";
import { useTranslation } from "react-i18next";

export const canNotUploadDataModalTitle = "can_not_upload_your_data";

type CanNotUploadDataModalProps = {
  clickHandler?: () => void;
};

const CanNotUploadDataModalContent: FunctionComponent<
  CanNotUploadDataModalProps
> = ({ clickHandler }) => {
  const { t } = useTranslation("common");

  return (
    <div className={styles.container}>
      <div className={styles.info}>
        {t("can_not_upload_data_modal_content_info_1")}
        <br /> {t("can_not_upload_data_modal_content_info_2")}
        <br />
        <br /> {t("can_not_upload_data_modal_content_info_3")}
        <br />
        <br /> {t("can_not_upload_data_modal_content_info_4")}
      </div>
      <div className={styles.buttonsContainer}>
        <div className={styles.firstButtonContainer}>
          <Button
            type={ButtonTypes.big}
            text={t("button_try_again")}
            icon={images.rightArrow}
            clickHandler={clickHandler}
          />
        </div>
      </div>
    </div>
  );
};

export default CanNotUploadDataModalContent;

import Bugsnag from "@bugsnag/js";
import Button, { ButtonTypes } from "components/Button/Button";
import SecondaryButton from "components/SecondaryButton/SecondaryButton";
import apiCall, { Endpoints } from "modules/api";
import { useApp } from "modules/context/AppProvider";
import { useUser } from "modules/context/UserProvider";
import { images } from "modules/images";
import { FunctionComponent, useState } from "react";
import { DeviceId } from "modules/constants";
import styles from "./RevokeConsent.module.scss";
import { useNavigate } from "react-router";
import { RoutesPaths } from "modules/routes";
import { useTranslation } from "react-i18next";

export const revokeConsentModalTitle = "revoking_consent";

type RevokeConsentModalProps = {
  device?: DeviceId;
  callback?: () => void;
  appleHandler?: () => void;
  isWellDoneScreen?: boolean;
};

const RevokeConsentModalContent: FunctionComponent<RevokeConsentModalProps> = ({
  device,
  callback,
  appleHandler,
  isWellDoneScreen,
}) => {
  const navigate = useNavigate();
  const { closeAlert } = useApp();
  const { user } = useUser();
  const [pending, setPending] = useState(false);
  const { t } = useTranslation("common");

  const handleDisconnect = async (device) => {
    if (!user?.token) return;

    try {
      if (device === "apple") {
        closeAlert();
        appleHandler();
      } else {
        setPending(true);
        await apiCall.delete(`${Endpoints.authProvider}${device}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: user.token,
          },
        });

        await callback();
        closeAlert();
      }
    } catch (error) {
      Bugsnag.notify(error);
    } finally {
      setPending(false);
    }
  };

  const handleRedirect = () => {
    navigate(RoutesPaths.chooseDevices);
    closeAlert();
  };

  return (
    <div className={styles.container}>
      <div className={styles.info}>
        {t("revoke_consent_modal_title")}
        <br />
        {t("revoke_consent_modal_text")}
      </div>
      <div className={styles.buttonsContainer}>
        {pending ? (
          <div className={styles.pending}>
            <img src={images.pending} alt={t("status_pending")} />
          </div>
        ) : (
          <>
            <div className={styles.firstButtonContainer}>
              <Button
                type={ButtonTypes.big}
                text={t("button_cancel")}
                clickHandler={closeAlert}
              />
            </div>
            <SecondaryButton
              text={t("button_disconnect")}
              clickHandler={() => {
                isWellDoneScreen ? handleRedirect() : handleDisconnect(device);
              }}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default RevokeConsentModalContent;

import { useUser } from "./UserProvider";
import { images } from "modules/images";

export enum Customer {
  test = "test",
  hh = "healthyhealth",
  aia = "aia",
  natgen = "natgen",
  aviva = "aviva",
  presales = "presales",
}

type Theme = {
  appName: string;
  headerIcon: string;
  welcomeImage: string;

  /** days of data required */
  dataRequired: number;

  colors: {
    /** main background color used for buttons */
    button: string;
    /** used for all secondary items links */
    secondary: string;
    /** main background color used for header */
    background: string;
    /** alternate background color for screens, progress bar background */
    secondaryBackground: string;
    /** background color for modals, tutorials etc */
    modalBackground: string;
    /** Message Color on the success sccreen */
    successMessageColor?: string;
  };
};

const defaultTheme: Theme = {
  appName: "National General",
  headerIcon: images.oneclick,
  welcomeImage: images.healthyHealthIllustration,
  dataRequired: 1,
  colors: {
    button: "rgb(247, 144, 30)",
    secondary: "rgb(0, 95, 174)",
    background: "#4D2AE1",
    secondaryBackground: "rgb(1, 80, 145)",
    modalBackground: "rgb(41, 33, 74)",
    successMessageColor: "#rgb(36, 154, 167)",
  },
};

const natgen: Theme = {
  appName: "National General",
  headerIcon: images.oneclick,
  welcomeImage: images.healthyHealthIllustration,
  dataRequired: 1,
  colors: {
    button: "rgb(247, 144, 30)",
    secondary: "rgb(0, 95, 174)",
    background: "rgb(0, 95, 174)",
    secondaryBackground: "rgb(1, 80, 145)",
    modalBackground: "rgb(41, 33, 74)",
    successMessageColor: "#rgb(36, 154, 167)",
  },
};

const aia: Theme = {
  appName: "AIA Connect",
  headerIcon: images.oneclick,
  welcomeImage: images.healthyHealthIllustration,
  dataRequired: 14,
  colors: {
    button: "#D31145",
    secondary: "#22A8DA",
    background: "#D31145",
    secondaryBackground: "#22A8DA",
    modalBackground: "#D31145",
    successMessageColor: "#22A8DA",
  },
};

const aviva: Theme = {
  appName: "OneClick",
  headerIcon: images.oneclick,
  welcomeImage: images.healthyHealthIllustration,
  dataRequired: 14,
  colors: {
    button: "rgb(77, 42, 225)",
    secondary: "rgb(77, 42, 225)",
    background: "rgb(77, 42, 225)",
    secondaryBackground: "rgb(65, 35, 191)",
    modalBackground: "rgb(77, 42, 225)",
  },
};

export const appConfig = {
  [Customer.hh]: defaultTheme,
  [Customer.presales]: defaultTheme,
  [Customer.natgen]: natgen,
  [Customer.aia]: aia,
  [Customer.aviva]: aviva,
};

export function useAppTheme(): Theme {
  const { user } = useUser();

  return appConfig[user?.customer] || defaultTheme;
}

import React from "react";
import styles from "./ContactUsButtonBig.module.scss";
import { useApp } from "modules/context/AppProvider";
import { ReactComponent as ContactButtonWhite } from "assets/images/icons/contact-us-white.svg";
import { useTranslation } from "react-i18next";

const ContactUsButtonBig: React.FunctionComponent = () => {
  const { showContactUs } = useApp();
  const { t } = useTranslation("common");

  return (
    <div onClick={showContactUs} className={styles.wrapper}>
      <span>{t("button_contact_us")}</span>
      <ContactButtonWhite className={styles.bigIcon} />
    </div>
  );
};

export default ContactUsButtonBig;

import React from "react";
import { Route, Routes } from "react-router-dom";

import { RoutesPaths } from "modules/routes";
import Header from "components/Header/Header";

import Home from "screens/Home/Home";
import SignIn from "screens/SignIn/SignIn";
import ChooseDevices from "screens/ChooseDevices/ChooseDevices";

import ConnectAppleHealth from "screens/ConnectAppleHealth/ConnectAppleHealth";
import ConnectGoogleFit from "screens/ConnectGoogleFit/ConnectGoogleFit";
import ConnectGoogleTimeline from "screens/ConnectGoogleTimeline";
import ConnectFitbit from "screens/ConnectFitbit/ConnectFitbit";
import ConnectGarmin from "screens/ConnectGarmin/ConnectGarmin";
import ConnectUnderarmour from "screens/ConnectUnderarmour/ConnectUnderarmour";

import UploadingGoogleFit from "screens/UploadingGoogleFit/UploadingGoogleFit";
import UploadingFitbit from "screens/UploadingFitbit/UploadingFitbit";
import UploadingGarmin from "screens/UploadingGarmin/UploadingGarmin";
import UploadingUnderarmour from "screens/UploadingUnderarmour/UploadingUnderarmour";

import Profile from "screens/Profile/Profile";
import Conditions from "screens/Conditions/conditions";
import WellDone from "screens/WellDone";
import ThankYou from "screens/ThankYou";

import NotFound from "screens/NotFound";
import ConnectGoogleTimelineTutorial from "./screens/ConnectGoogleTimelineTutorial";

const AppNavigator: React.FC = () => (
  <>
    <Header />
    <Routes>
      <Route path="/" element={<Home />} />

      {/* Sign in screen with policies */}
      <Route path={RoutesPaths.signIn} element={<SignIn />} />

      {/* Choose providers screen */}
      <Route path={RoutesPaths.chooseDevices} element={<ChooseDevices />} />

      {/* Providers screens */}
      <Route
        path={RoutesPaths.connectAppleHealth}
        element={<ConnectAppleHealth />}
      />
      <Route
        path={RoutesPaths.connectGoogleFit}
        element={<ConnectGoogleFit />}
      />
      <Route path={RoutesPaths.connectFitbit} element={<ConnectFitbit />} />
      <Route path={RoutesPaths.connectGarmin} element={<ConnectGarmin />} />
      <Route
        path={RoutesPaths.connectUnderarmour}
        element={<ConnectUnderarmour />}
      />
      <Route
        path={RoutesPaths.connectGoogleTimeline}
        element={<ConnectGoogleTimeline />}
      />
      <Route
        path={RoutesPaths.connectGoogleTimelineTutorial}
        element={<ConnectGoogleTimelineTutorial />}
      />

      <Route
        path={RoutesPaths.uploadingGoogleFit}
        element={<UploadingGoogleFit />}
      />
      <Route path={RoutesPaths.uploadingFitbit} element={<UploadingFitbit />} />
      <Route path={RoutesPaths.uploadingGarmin} element={<UploadingGarmin />} />
      <Route
        path={RoutesPaths.uploadingUnderarmour}
        element={<UploadingUnderarmour />}
      />

      {/* Profile screen */}
      <Route path={RoutesPaths.profile} element={<Profile />} />

      {/* Conditions screen */}
      <Route path={RoutesPaths.conditions} element={<Conditions />} />

      {/* Result screens */}
      <Route path={RoutesPaths.wellDone} element={<WellDone />} />
      <Route path={RoutesPaths.thankYou} element={<ThankYou />} />

      {/* Not found screen */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  </>
);

export default AppNavigator;

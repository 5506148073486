import React from "react";
import { useUser } from "modules/context/UserProvider";
import DeviceItemStatus from "./DeviceItemStatus";
import { DeviceId } from "modules/constants";
import { images } from "modules/images";
import styles from "./DeviceItem.module.scss";
import { ProviderId } from "modules/api";
import { useApp } from "modules/context/AppProvider";
import { useTranslation } from "react-i18next";
import Button, { ButtonTypes } from "components/Button/Button";
import { useNavigate } from "react-router-dom";
import { RoutesPaths } from "../../modules/routes";

export interface DeviceDataInterface {
  connected: boolean;
  days: number;
  has_enough_data: boolean;
  is_receiving_data: boolean;
  name?: DeviceId;
}

export type DeviceItemType = {
  name?: String;
  title: String;
  subtitle?: String;
  connectionRoute: String;
  icon: string;
};

interface DeviceItemPropsInterface {
  status?: DeviceDataInterface;
  device: DeviceItemType;
  isLoading: boolean;
  handleNext: (route: String) => void;
  openRevokeModal: (name: String) => void;
}

const Icon = ({
  device,
  connected,
}: {
  device: String;
  connected: boolean;
}) => {
  const isGoogleTimeline = device === ProviderId.googleTimeline;
  const isGoogleTimelineConnected =
    isGoogleTimeline && localStorage.getItem("@GoogleTimeline");

  if (isGoogleTimeline) {
    return (
      <img
        src={
          isGoogleTimelineConnected ? images.cloudChecked : images.uploadFile
        }
        alt="cloud icon"
        className={styles.cloudChecked}
      />
    );
  }

  return (
    <div
      className={`${styles["switch-container"]} ${
        connected ? styles.connected : ""
      }`}
    >
      <div
        className={`${styles.switch} ${connected ? styles.connected : ""}`}
      />
    </div>
  );
};

const DeviceItem: React.FunctionComponent<DeviceItemPropsInterface> = ({
  device,
  status,
  isLoading,
  handleNext,
  openRevokeModal,
}) => {
  const { user } = useUser();
  const { showAlert, closeAlert } = useApp();
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const isGoogleTimeline = device.name === ProviderId.googleTimeline;
  const isGoogleTimelineConnected =
    isGoogleTimeline && localStorage.getItem("@GoogleTimeline");
  const handleClick = () => {
    if (isLoading) {
      return;
    }

    if (isGoogleTimelineConnected) {
      return status.connected
        ? showAlert(
            t("thank_you"),
            <div className={styles.popupContainer}>
              <p className={styles.popupText}>{t("gtl_your_data_uploaded")}</p>
            </div>,
          )
        : showAlert(
            t("device_item_status_not_enough_data"),
            <div className={styles.popupContainer}>
              <p className={styles.popupText}>{t("gtl_not_enough_data")}</p>
              <Button
                type={ButtonTypes.big}
                text={t("button_upload_files")}
                clickHandler={() => {
                  closeAlert();
                  navigate(RoutesPaths.connectGoogleTimelineTutorial);
                }}
              />
            </div>,
          );
    }

    if (status?.connected) {
      openRevokeModal(device.name);
    } else {
      handleNext(device.connectionRoute);
    }
  };

  const hasStatus =
    status?.connected ||
    !!(user?.id && localStorage.getItem(device.name + "_tried" + user.id));

  return (
    <div className={styles.wrapper} onClick={handleClick}>
      <div className={styles.left}>
        <div className={styles.logoContainer}>
          <img alt="logo" className={styles.icon} src={device.icon} />
        </div>
        <div className={styles.deviceItem}>
          <div className="device-name">
            <span className={styles.deviceTitle}>{device.title}</span>{" "}
            <span className={styles.deviceSubtitle}>{device.subtitle}</span>
          </div>

          <div className="status-container">
            <DeviceItemStatus
              status={status}
              hasStatus={hasStatus}
              isLoading={isLoading}
              isGoogleTimeline={isGoogleTimeline}
            />
          </div>
        </div>
      </div>

      <div className={styles.right}>
        {isLoading ? (
          <img src={images.spinner} alt="loading" className={styles.spinner} />
        ) : (
          <Icon device={device.name} connected={status?.connected} />
        )}
      </div>
    </div>
  );
};

export default DeviceItem;

import qs from "qs";
import React from "react";
import { useNavigate } from "react-router";
import DeviceHeader from "components/DeviceHeader/DeviceHeader";
import { useApp } from "modules/context/AppProvider";
import { AccessStatus, DeviceId } from "modules/constants";
import Layout from "components/Layout/Layout";
import { RoutesPaths } from "modules/routes";
import styles from "./DeviceDataUploading.module.scss";
import Bugsnag from "@bugsnag/js";
import {
  useCollectionStatusQuery,
  CollectionStatusProviderResponse,
} from "modules/utils/hooks";
import Footer from "components/Footer/Footer";
import CanNotUploadDataModalContent, {
  canNotUploadDataModalTitle,
} from "components/Modals/CanNotUploadData/CanNotUploadData";
import { useUser } from "modules/context/UserProvider";
import { useTranslation } from "react-i18next";

export const THRESHOLD_REFETCH_COUNT = 4;
export const FIFTEEN_SECONDS = 15000;

const DeviceDataUploading: React.FunctionComponent<{
  deviceId: DeviceId;
}> = ({ deviceId }) => {
  const { user } = useUser();
  const refetchCount = React.useRef<number>(0);
  const { showAlert, closeAlert } = useApp();
  const navigate = useNavigate();
  const { t } = useTranslation("common");

  const query = window.location.search?.replace("?", "");
  const { status } = qs.parse(query);

  const { data, isFetching } =
    useCollectionStatusQuery<CollectionStatusProviderResponse>(deviceId, {
      refetchInterval: FIFTEEN_SECONDS,
    });

  const mapIdToUrl = {
    [DeviceId.fitbit]: RoutesPaths.connectFitbit,
    [DeviceId.garmin]: RoutesPaths.connectGarmin,
    [DeviceId.google]: RoutesPaths.connectGoogleFit,
    [DeviceId.underarmour]: RoutesPaths.connectUnderarmour,
  };

  const url = mapIdToUrl[deviceId];

  React.useEffect(() => {
    if (isFetching) return;

    try {
      if (status.toString().replace("#_=_", "") !== "success") {
        navigate(url);
      }

      const count = refetchCount?.current ?? 0;
      const hasEnoughData = data && data.has_enough_data;
      const refetchMaxCount = user?.redirect
        ? THRESHOLD_REFETCH_COUNT * 3
        : THRESHOLD_REFETCH_COUNT;
      const isThresholdReached = count >= refetchMaxCount || hasEnoughData;

      if (isThresholdReached || hasEnoughData) {
        navigate(url);
      }

      refetchCount.current = count + 1;
    } catch (error) {
      Bugsnag.notify(error);

      showAlert(
        t(canNotUploadDataModalTitle),
        <CanNotUploadDataModalContent
          clickHandler={() => {
            closeAlert();
            navigate(url);
          }}
        />,
      );
    }
  }, [
    closeAlert,
    data,
    isFetching,
    navigate,
    showAlert,
    status,
    url,
    t,
    user?.redirect,
  ]);

  if (!deviceId) {
    return null;
  }

  return (
    <Layout progressBarPercent={40}>
      <div className={styles.wrapper}>
        <div className={styles.centerContainer}>
          <div className={styles.headerContainer}>
            <DeviceHeader device={deviceId} status={AccessStatus.loading} />{" "}
          </div>
          <div className={styles.infoContainer}>
            {t("device_data_uploading_info_1")}
            <br />
            {t("device_data_uploading_info_2")}
          </div>

          <div className={styles.footerContainer}>
            <Footer />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default DeviceDataUploading;

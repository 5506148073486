import Button, { ButtonTypes } from "components/Button/Button";
import { images } from "modules/images";
import { FunctionComponent } from "react";
import styles from "./AppleTutorialMobileHint.module.scss";
import { ReactComponent as CloseButton } from "assets/images/icons/close.svg";
import { useTranslation } from "react-i18next";

interface AppleTutorialMobileHintInterface {
  opened: boolean;
  onClose: () => void;
}

const AppleTutorialMobileHint: FunctionComponent<
  AppleTutorialMobileHintInterface
> = ({ opened, onClose }) => {
  const { t } = useTranslation("common");

  return opened ? (
    <div className={styles.wrapper} style={{ height: "100%" }}>
      <div className={styles.container}>
        <div className={styles.header}>
          <button onClick={onClose} className={styles.closeButton}>
            <CloseButton />
          </button>
        </div>
        <div className={styles.infoContainer}>
          <div className={styles.title}>
            {t("apple_tutorial_mobile_hint_title")}
          </div>
          <div className={styles.info}>
            {t("apple_tutorial_mobile_hint_info")}
          </div>
          <div className={styles.buttonContainer}>
            <Button
              type={ButtonTypes.big}
              text="Start"
              icon={images.rightArrow}
              clickHandler={onClose}
            />
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default AppleTutorialMobileHint;

import React from "react";
import classNames from "classnames";
import "./styles.scss";
import { useAppTheme } from "modules/context/ThemeProvider";

interface ProgressBarProps {
  progress: number;
}

const ProgressBar: React.FunctionComponent<ProgressBarProps> = ({
  progress,
}) => {
  const progressBarContainer = classNames("progress-bar-container");
  const { colors } = useAppTheme();

  return (
    <div className={progressBarContainer}>
      <div className="progress-bar-background">
        <div
          className="filled-area"
          style={{ width: `${progress}%`, backgroundColor: colors.background }}
        />
      </div>
    </div>
  );
};

export default ProgressBar;

import { images } from "modules/images";
import { FunctionComponent } from "react";
import styles from "./BackButton.module.scss";

const BackButton: FunctionComponent = () => {
  return (
    <div className={styles.wrapper}>
      <img src={images.leftArrow} alt="Back" />
    </div>
  );
};

export default BackButton;

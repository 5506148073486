import React from "react";
import DeviceHeader from "components/DeviceHeader/DeviceHeader";
import styles from "./StartConnectGoogle.module.scss";
import { DeviceId } from "modules/constants";
import Button, { ButtonTypes } from "components/Button/Button";
import SecondaryButton from "components/SecondaryButton/SecondaryButton";
import { images } from "modules/images";
import Footer from "components/Footer/Footer";
import TextButton from "components/TextButton/TextButton";
import { useNavigate } from "react-router-dom";
import { RoutesPaths } from "modules/routes";
import { useApp } from "modules/context/AppProvider";
import GoogleFitAlert, {
  GoogleFitAlertTitle,
} from "components/Modals/GoogleFitAlert/GoogleFitAlert";
import { useTranslation } from "react-i18next";

type StartConnectDeviceProps = {
  device: DeviceId;
  onConnect: () => void;
  isLoading: boolean;
};

const StartConnectGoogle: React.FunctionComponent<StartConnectDeviceProps> = ({
  device,
  onConnect,
}) => {
  const navigate = useNavigate();
  const { showAlert } = useApp();
  const { t } = useTranslation("common");

  const showGoogleAlert = () =>
    showAlert(GoogleFitAlertTitle, <GoogleFitAlert />);

  return (
    <div className={styles.wrapper}>
      <div className={styles.centerContainer}>
        <div className={styles.headerContainer}>
          <DeviceHeader device={device} />
        </div>

        <div className={styles.subHeader}>
          {t("start_connect_google_sub_title")}
        </div>

        <div className={styles.aboutTitle}>About Google Fit app</div>
        <div className={styles.aboutText}>{t("start_connect_google_text")}</div>

        <div className={styles.aboutButtonContainer}>
          <TextButton
            text={t("what_is_google_account")}
            clickHandler={showGoogleAlert}
          />
        </div>

        <div className={styles.buttonsContainer}>
          <Button
            type={ButtonTypes.big}
            text={t("button_sign_in")}
            icon={images.rightArrow}
            clickHandler={onConnect}
          />

          <div className={styles.cancelButtonContainer}>
            <SecondaryButton
              text={t("button_cancel")}
              clickHandler={() => navigate(RoutesPaths.chooseDevices)}
            />
          </div>
        </div>

        <div className={styles.footerContainer}>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default StartConnectGoogle;

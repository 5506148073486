export const RoutesPaths = {
  home: "/",
  appleHealthTutorial: "/apple-health-tutorial",
  appleHealthTutorial13: "/apple-health-tutorial-13",

  awaitAppleHealthConnection: "/await-apple-health",
  chooseDevices: "/choose-devices",
  reselectDevices: "/reselect-devices",
  conditions: "/conditions",
  connectAppleHealth: "/connect-apple-health",
  connectAppleWatch: "/connect-apple-watch",
  connectGoogleTimeline: "/connect-google-timeline",
  connectGoogleTimelineTutorial: "/connect-google-timeline-tutorial",

  connectFitbit: "/connect-fitbit",
  uploadingFitbit: "/uploading-fitbit",
  connectGarmin: "/connect-garmin",
  uploadingGarmin: "/uploading-garmin",
  connectGoogleFit: "/connect-google-fit",
  uploadingGoogleFit: "/uploading-google-fit",
  connectUnderarmour: "/connect-underarmour",
  uploadingUnderarmour: "/uploading-underarmour",

  installGoogleFit: "/install-google-fit",
  profile: "/profile",
  selectDevices: "/select-devices",
  setUpGoogleFit: "/set-up-google-fit",
  signIn: "/sign-in",
  thankYou: "/thank-you",
  wellDone: "/well-done",
};

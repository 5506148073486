import React from "react";
import ProgressBar from "components/ProgressBar";
import styles from "./Layout.module.scss";

interface LayoutProps {
  children?: React.ReactNode;
  isProgressBar?: boolean;
  progressBarPercent?: number;
}

const Layout: React.FunctionComponent<LayoutProps> = ({
  children,
  isProgressBar = true,
  progressBarPercent,
}) => {
  return (
    <>
      {isProgressBar && <ProgressBar progress={progressBarPercent} />}
      <div className={styles.wrapper}>{children}</div>
    </>
  );
};

export default Layout;

import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import {
  useCollectionStatusQuery,
  useProfileMutation,
  useProfileQuery,
} from "modules/utils/hooks";
import Layout from "components/Layout/Layout";
import { RoutesPaths } from "modules/routes";
import { useApp } from "modules/context/AppProvider";
import Bugsnag from "@bugsnag/js";
import styles from "./Conditions.module.scss";
import Condition from "components/Condition/Condition";
import Button, { ButtonTypes } from "components/Button/Button";
import { images } from "modules/images";
import Footer from "components/Footer/Footer";
import SelectConditionsModalContent, {
  selectConditionsModalTitle,
} from "components/Modals/SelectConditions/SelectConditions";
import DefaultErrorModalContent, {
  defaultErrorModalTitle,
} from "components/Modals/DefaultError/DefaultError";
import { conditions, conditionsValues } from "modules/helper";
import { useTranslation } from "react-i18next";

const Conditions: React.FunctionComponent<{}> = () => {
  const navigate = useNavigate();
  const [isSubmitted, setSubmitted] = useState(false);
  const { mutate } = useProfileMutation();
  const { data: profileData, isLoading: isProfileLoading } = useProfileQuery();
  const [valuesPresented, setValuesPresented] = useState(false);
  const [initialValues, setInitialValues] = useState(conditionsValues);
  const { showAlert } = useApp();
  const { data: providersStatus } = useCollectionStatusQuery();
  const { t } = useTranslation("common");

  const hasEnoughData =
    providersStatus &&
    Object.values(providersStatus).some((status) => status.has_enough_data);

  const submit = async (values) => {
    setSubmitted(true);

    try {
      const conditions: string[] = Object.keys(conditionsValues).filter(
        (key) => initialValues[key],
      );

      if (!conditions.some((i) => i)) {
        setSubmitted(false);

        return showAlert(
          t(selectConditionsModalTitle),
          <SelectConditionsModalContent />,
        );
      }

      if (conditions.includes("none")) conditions.length = 0;

      await mutate({ conditions });

      if (hasEnoughData) {
        navigate(RoutesPaths.thankYou);
      } else {
        navigate(RoutesPaths.wellDone);
      }
    } catch (error) {
      Bugsnag.notify(error);
      showAlert(t(defaultErrorModalTitle), <DefaultErrorModalContent />);
    }

    setSubmitted(false);
  };

  useEffect(() => {
    if (valuesPresented) return;

    if (!isProfileLoading && profileData) {
      const conditionsToUpdate = Object.assign({}, initialValues);

      for (let key in initialValues) {
        if (profileData.conditions.includes(key))
          conditionsToUpdate[key] = true;
      }

      if (JSON.stringify(conditionsToUpdate) !== JSON.stringify(initialValues))
        setInitialValues(conditionsToUpdate);

      setValuesPresented(true);
    }
  }, [isProfileLoading, profileData, initialValues, valuesPresented]);

  const changeValue = (key: string) => {
    const conditionsToUpdate = Object.assign({}, initialValues);

    if (key === "none") {
      for (let item in conditionsToUpdate) {
        if (item !== "none") conditionsToUpdate[item] = false;
      }

      conditionsToUpdate[key] = !conditionsToUpdate[key];
    } else {
      conditionsToUpdate[key] = !conditionsToUpdate[key];
      if (conditionsToUpdate.none === true) conditionsToUpdate.none = false;
    }

    setInitialValues(conditionsToUpdate);
  };

  return (
    <Layout progressBarPercent={80}>
      <div className={styles.wrapper}>
        <div className={styles.centerContainer}>
          <div className={styles.header}>{t("pre_existing_conditions")}</div>
          <div className={styles.info}>{t("conditions_info")}</div>
          <div className={styles.conditionsHeader}>{t("please_select")}</div>
          <Formik
            onSubmit={(values) => submit(values)}
            initialValues={{
              ...conditionsValues,
            }}
          >
            {({
              values: { ...conditionsValues },
              handleChange,
              handleSubmit,
            }) => {
              return (
                <>
                  {Object.keys(initialValues).map((key, index) => (
                    <Condition
                      key={key}
                      index={index}
                      checked={initialValues[key]}
                      label={t(conditions[key])}
                      clickHandler={() => {
                        changeValue(key);
                        handleChange(key);
                      }}
                    />
                  ))}
                  <div className="">{isSubmitted && <div></div>}</div>

                  <div className={styles.bottomContainer}>
                    <Button
                      type={ButtonTypes.big}
                      text={t("button_continue")}
                      icon={images.rightArrow}
                      clickHandler={handleSubmit}
                      disabled={isSubmitted || !valuesPresented}
                    />

                    <div className={styles.footerContainer}>
                      <Footer />
                    </div>
                  </div>
                </>
              );
            }}
          </Formik>
        </div>
      </div>
    </Layout>
  );
};

export default Conditions;

import AppleHealthTutorialDesktop from "components/AppleHealthTutorialDesktop/AppleHealthTutorialDesktop";
import AppleHealthTutorialMobile from "components/AppleHealthTutorialMobile/AppleHealthTutorialMobile";
import { FunctionComponent } from "react";
import styles from "./AppleHealthTutorial.module.scss";

interface AppleHealthTutorialInterface {
  onClose: () => void;
}

const AppleHealthTutorial: FunctionComponent<AppleHealthTutorialInterface> = ({
  onClose,
}) => {
  return (
    <div className={styles.wrapper} style={{ height: "100%" }}>
      <AppleHealthTutorialMobile onClose={onClose} />
      <AppleHealthTutorialDesktop onClose={onClose} />
    </div>
  );
};

export default AppleHealthTutorial;

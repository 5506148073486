import React from "react";
import { Results } from "./index";
import { useTranslation } from "react-i18next";
import styles from "./style.module.scss";
import { useNavigate } from "react-router-dom";
import { RoutesPaths } from "../../modules/routes";
import Footer from "../../components/Footer/Footer";
import StartButton from "../../components/StartButton/StartButton";
import Button, { ButtonTypes } from "../../components/Button/Button";
import { images } from "../../modules/images";

const ResultGoogleTimeline = ({
  status,
  reset,
}: {
  status: Results;
  reset: () => void;
}) => {
  const { t } = useTranslation("common");
  const navigate = useNavigate();

  const isSuccess = status === Results.success;

  const title = isSuccess ? "done_title" : "upload_was_unsuccessful";
  const text = isSuccess ? "done_text" : "upload_was_unsuccessful_text";

  return (
    <div className={styles.doneContainer}>
      <div className={styles.topContainer}>
        <h1 className={styles.doneTitle}>{t(title)}</h1>
        <p
          className={styles.doneText}
          dangerouslySetInnerHTML={{ __html: t(text) }}
        />
        {isSuccess && (
          <div className={styles.buttonsContainer}>
            <StartButton
              text={t("button_got_it")}
              clickHandler={() => navigate(RoutesPaths.chooseDevices)}
            />
          </div>
        )}
      </div>

      <div className={`${styles.bottom} ${isSuccess ? styles.flexEnd : ""}`}>
        {isSuccess ? (
          <p className={styles.disconnectText}>
            {t("footer_text")}
            <strong onClick={() => navigate(RoutesPaths.chooseDevices)}>
              {t("button_disconnect").toLowerCase()}
            </strong>
          </p>
        ) : (
          <div className={styles.rowButtons}>
            <Button
              icon={images.rightArrow}
              type={ButtonTypes.big}
              text={t("instruction_page")}
              clickHandler={reset}
              style={styles.continue}
            />
            <Button
              style={styles.cancelButton}
              type={ButtonTypes.secondary}
              text={t("try_another_device")}
              clickHandler={() => navigate(RoutesPaths.chooseDevices)}
            />
          </div>
        )}

        <div className={styles.footerContainer}>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default ResultGoogleTimeline;

import React from "react";
import { DeviceId } from "modules/constants";
import styles from "./ConnectGoogleTimeline.module.scss";
import DeviceHeader from "../../components/DeviceHeader/DeviceHeader";
import Button, { ButtonTypes } from "../../components/Button/Button";
import { images } from "../../modules/images";
import SecondaryButton from "../../components/SecondaryButton/SecondaryButton";
import { RoutesPaths } from "../../modules/routes";
import Footer from "../../components/Footer/Footer";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ProgressBar from "../../components/ProgressBar";

const Index: React.FunctionComponent = () => {
  const navigate = useNavigate();

  const { t } = useTranslation("common");

  return (
    <>
      <ProgressBar progress={20} />

      <div className={styles.wrapper}>
        <div className={styles.centerContainer}>
          <div className={styles.headerContainer}>
            <DeviceHeader device={DeviceId.googleTimeline} />
            <div
              className={styles.subHeader}
              dangerouslySetInnerHTML={{
                __html: t("googleTimeline_connection_text"),
              }}
            />
            <div className={styles.rowItem}>
              <img
                src={images.googleTimelineUser}
                alt="google timeline user"
                className={styles.googleTimelineIcon}
              />

              <div
                dangerouslySetInnerHTML={{
                  __html: t("googleTimeline_connection_text_1"),
                }}
              />
            </div>
            <div className={styles.rowItem}>
              <img
                src={images.googleTimelinePassword}
                alt="google timeline password"
                className={styles.googleTimelineIcon}
              />

              <div
                dangerouslySetInnerHTML={{
                  __html: t("googleTimeline_connection_text_2"),
                }}
              />
            </div>
          </div>

          <div className={styles.buttonsContainer}>
            <Button
              type={ButtonTypes.big}
              text={t("button_start_the_tutorial")}
              icon={images.rightArrow}
              clickHandler={() =>
                navigate(RoutesPaths.connectGoogleTimelineTutorial)
              }
            />
            <div className={styles.cancelButtonContainer}>
              <SecondaryButton
                text={t("button_cancel")}
                clickHandler={() => navigate(RoutesPaths.chooseDevices)}
              />
            </div>

            <div className={styles.footerContainer}>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;

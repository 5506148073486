import LeaveModalContent, {
  leaveModalTitle,
} from "components/Modals/Leave/Leave";
import { useApp } from "modules/context/AppProvider";
import { FunctionComponent } from "react";
import styles from "./HomeButton.module.scss";
import { ReactComponent as HomeIcon } from "assets/images/icons/home.svg";
import { useTranslation } from "react-i18next";

const HomeButton: FunctionComponent = () => {
  const { showAlert } = useApp();
  const { t } = useTranslation("common");

  const onShowClosePopup = () =>
    showAlert(t(leaveModalTitle), <LeaveModalContent />);

  return (
    <div className={styles.wrapper} onClick={onShowClosePopup}>
      <HomeIcon className={styles.icon} />
      <span>{t("home_page")}</span>
    </div>
  );
};

export default HomeButton;

import { FunctionComponent } from "react";
import styles from "./GoogleFitAlert.module.scss";
import { useTranslation } from "react-i18next";

export const GoogleFitAlertTitle: string = "Google Fit Account";

const GoogleFitAlert: FunctionComponent = () => {
  const { t } = useTranslation("common");

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        {t("google_fit_alert_title")}
        <br />
        <br />
        {t("google_fit_alert_title")}
        {t("google_fit_alert_text_1")}
      </div>
      <div className={styles.haveContainer}>
        <h2 className={styles.subHeader}>
          {t("google_fit_alert_I_have_account")}
        </h2>
        {t("google_fit_alert_title")}
        {t("google_fit_alert_text_2")}
      </div>
      <div className={styles.dontHaveContainer}>
        <h2 className={styles.subHeader}>
          {t("google_fit_alert_I_dont_have_account")}
        </h2>
        {t("google_fit_alert_please_try_connect")}
        <br />
        {t("google_fit_alert_text_3")}
      </div>
    </div>
  );
};

export default GoogleFitAlert;
